import React, { useState } from 'react';
import { isEmpty } from 'lodash';

import { Grid, MenuItem, Paper, Select, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

import { Colors } from '../config/default';
import { BootstrapInput } from '../services/common';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function DropdownEmails({
  index,
  setMails,
  mails,
  apiData,
  toShow,
  fullWidth,
  height
}) {
  const [selectedMail, setSelectedMail] = useState(
    (toShow === 'policy' ? mails[index] : toShow === 'vendor' ? mails : []) || []
  );

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    const validValues = (Array.isArray(value) ? value : [value])?.filter(Boolean);
    if (validValues?.length > 0) {
      const newMail = typeof value === 'string' ? value?.split(',')?.filter(Boolean) : validValues;
      setSelectedMail(newMail);

      if (toShow === 'vendor') {
        setMails(newMail);
      } else {
        const updatedEmails = [...mails];
        updatedEmails[index] = newMail;
        setMails(updatedEmails);
      }
    }
  };
  return (
    <div>
      <Grid item>
        {toShow !== 'canvas' && toShow !== 'vendor' && (
          <Typography sx={{ fontSize: '0.75em', color: Colors.WHITE, marginBottom: '.2em' }}>
            Approver {index + 1}
          </Typography>
        )}
        <Select
          fullWidth={fullWidth ? true : false}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          variant="outlined"
          label={toShow === 'canvas' ? 'Select a user for approval' : ''}
          sx={{
            borderRadius: '0.6em',
            width: toShow === 'canvas' ? '100%' : toShow === 'vendor' ? '17em' : '14em',
            outline: 'none',
            color: Colors.TEXTGREY,
            height: toShow === 'canvas' ? '3.5em' : '2.8em',
            fontSize: toShow === 'canvas' ? '13px' : '0.65em',
            padding: toShow === 'canvas' ? '0em' : '1em 0em',
            textAlign: 'left',
            border: '.7px solid #BABABA'
          }}
          multiple
          value={selectedMail}
          onChange={handleChange}
          MenuProps={{
            PaperProps: {
              sx: {
                height: height && apiData?.length > 3 ? '180px' : 'max-content',
                borderRadius: '10px',
                backgroundColor: `${Colors.NAV_BLACK} !important`,
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '0.2em'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: Colors.TEXTGREY,
                  borderRadius: '10px'
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: Colors.NAV_BLACK,
                  borderRadius: '10px'
                }
              }
            }
          }}
          input={<BootstrapInput />}
          renderValue={(selected) => selected.join(', ')}>
          {isEmpty(apiData) ? (
            <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography sx={{ fontSize: '.6em' }}>
                {toShow === 'canvas'
                  ? "you don't have registered users"
                  : toShow === 'vendor'
                  ? 'Your selected list is empty'
                  : "you don't have more users"}
              </Typography>
            </Grid>
          ) : (
            apiData?.map((name) => (
              <MenuItem
                sx={{
                  color: Colors.TEXTGREY,
                  borderRadius: '8px',
                  margin: '0px 8px',
                  padding: '0px',
                  paddingRight: '.5em',
                  '&:hover': {
                    borderRadius: '7px',
                    backgroundColor: Colors.BG_BLACK
                  }
                }}
                key={name}
                value={name}>
                <Checkbox
                  style={{ color: Colors.TEXTGREY }}
                  size="small"
                  checked={selectedMail.indexOf(name) > -1}
                />
                <Typography sx={{ fontSize: toShow === 'canvas' ? '1rem' : '.9em' }}>
                  {name}
                </Typography>
              </MenuItem>
            ))
          )}
        </Select>
      </Grid>
    </div>
  );
}
