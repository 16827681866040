import React, { useState, useEffect } from 'react';
import Countdown from 'react-countdown';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Box, TextField, Typography, Grid, Hidden } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import MuiPhoneNumber from 'material-ui-phone-number';

import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';

import theme from '../theme';
import { Colors } from '../../src/config/default';
import Btn from '../components/button';
import {
  findUser,
  signUp,
  confirmSignup,
  resendCode,
  sendEmailtoAdmin,
  customerSignup,
  getInfo
} from '../services/services';
import { sign_In, user_information, get_tabs } from '../redux/actions/action';
import { hashEmailAndPassword, hashEmail } from '../services/common';
import {
  protectedSchema,
  ValidationMessages,
  toasters,
  Environment,
  headings,
  userRoles,
  IntegrationPage
} from '../constants/appConstants';
import { useToast } from '../toast/toastContext';

export default function SignUp({
  role,
  company,
  defaultMail,
  setSignIn,
  setIsHovered,
  defaultCompany,
  designationName
}) {
  const dispatch = useDispatch();
  const [isSignUp, setIsSignUp] = useState(true);
  const { SOMETHING_WENT_WRONG } = IntegrationPage;
  const [verficationCode, setVerficationCode] = useState('');
  const [companyName, setCompanyName] = useState(defaultCompany ? defaultCompany : '');
  const [counter, setCounter] = useState(true);
  const [isResendEnabled, setIsResendEnabled] = useState(true);
  const [showCountdownTimer, setShowCountdownTimer] = useState(true);
  const [showResendCode, setShowResendCode] = useState(false);
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [pass, setPass] = useState('');
  const [verfiedUser, setverfiedUser] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { showToast } = useToast();
  const { REQUIRED, INVALID_EMAIL, PASSWORD_CHECK, INVALID_USERNAME, PASSWORD_SCHEMA } =
    ValidationMessages;
  const { ALREADY_REGISTERED, VERIFY_ACCOUNT, RESEND_CODE, SECONDS, SIGNIN_HEADING } =
    protectedSchema;
  const {
    NAME_PLACEHOLDER,
    USER,
    SUBMIT,
    CREATE_ACCOUNT,
    EMAIL,
    WORK_EMAIL_PLACEHOLDER,
    PASSWORD,
    PASSWORD_PLACEHOLDER,
    VERIFICATION_CODE,
    CONFIRM
  } = headings;
  const { MANAGER, OWNER, ACCOUNTANT, CLEVEL, ADMIN } = userRoles;
  const { IS_CODE_SENT, EMAIL_ERROR, VERIFICATION, TIME_ERROR, EMAIL_NOTE, RESEND_CODE_LINK } =
    toasters;
  const spaceReplace = phone.replace(/ /g, '');

  const phoneFormat = spaceReplace.replace(/[^+a-zA-Z 0-9]+/g, '');
  const initialValues = {
    userName: '',
    email: defaultMail ? defaultMail : '',
    password: '',
    confirmPassword: ''
  };
  const navigate = useNavigate();
  const codeValueValidation = (value) => {
    if (value % 1 == 0) {
      setVerficationCode(value);
    }
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowPassword(false);
      setShowConfirmPassword(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [showPassword, showConfirmPassword]);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const validationSchema = Yup.object({
    userName: Yup.string()
      .trim()
      .required(REQUIRED)
      .matches(/^[a-zA-Z0-9_\s]+$/, INVALID_USERNAME),
    email: Yup.string().trim().email(INVALID_EMAIL).required(REQUIRED),
    password: Yup.string()
      .trim()
      .required(REQUIRED)
      .min(8, PASSWORD_CHECK)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~£])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~£]{8,}$/,
        PASSWORD_CHECK
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required')
  });
  const handleCompanyNameChange = (e) => {
    const inputValue = e.target.value;
    setCompanyName(inputValue);
  };
  const emailRes = async () => {
    const record = {
      email: name,
      username: verfiedUser,
      role: role
    };
    await sendEmailtoAdmin(record);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    setName(values.email);
    setverfiedUser(values.userName);
    setPass(values.password);

    const userApiResult = await findUser(values.email);

    if (userApiResult?.status === 200) {
      if (userApiResult?.data?.payload?.data.Users.length > 0) {
        if (userApiResult?.data?.payload?.data?.Users[0].UserStatus === 'CONFIRMED') {
          showToast(EMAIL_ERROR, 'error');
          setLoading(false);
        } else {
          startDate.current = Date.now();
          setIsSignUp(false);
          setLoading(false);
          const VerificationEmail = hashEmail(values?.email?.toLowerCase());
          var params = {
            email: VerificationEmail
          };
          const resendCodeApi = await resendCode(params);
          if (resendCodeApi?.status === 200) {
            startDate.current = Date.now();
          } else {
            showToast(resendCodeApi?.data?.payload?.data?.cognitoError?.message, 'error');
            setIsSignUp(true);
          }
        }
      } else {
        const { hashedEmail, hashedPassword } = hashEmailAndPassword(
          values?.email?.toLowerCase(),
          values.password
        );
        const params = {
          email: hashedEmail,
          given_name: role,
          name: values.userName,
          password: hashedPassword,
          phone_number: phoneFormat,
          companyId: role === 'Owner' ? '' : company
        };

        const signUpResult = await signUp(params);
        if (signUpResult?.status === 200) {
          dispatch(sign_In(signUpResult?.data?.payload?.data));
          userSignUp(params, values?.userName);
          startDate.current = Date.now();
        } else {
          showToast(
            signUpResult?.data?.payload?.data?.cognitoError?.message ||
              signUpResult?.response?.data?.metadata?.message,
            'error'
          );
          setLoading(false);
        }
      }
    } else {
      showToast(userApiResult?.data?.payload?.data?.cognitoError?.message, 'error');
    }
  };
  const userSignUp = async (data, name) => {
    const customerData = {
      email: data?.email,
      password: data?.password,
      userName: name,
      companyId: role === 'Owner' ? '' : company,
      phoneNumber: phoneFormat,
      role: role,
      companyName: companyName,
      name: role === 'Owner' ? 'AP Person' : designationName
    };
    const signUpRes = await customerSignup(customerData);
    if (signUpRes?.status === 200) {
      localStorage.clear();
      dispatch(get_tabs({}));
      dispatch(sign_In(signUpRes?.data?.payload?.data));
      const token = signUpRes?.data?.payload?.data?.cognitoRes?.idToken?.jwtToken;
      const setHeaders = () => {
        const headerConfig = {
          headers: { Authorization: 'Bearer ' + token }
        };
        return headerConfig;
      };
      const getUserresult = await getInfo(
        signUpRes?.data?.payload?.data?.cognitoRes?.accessToken?.payload?.username,
        setHeaders
      );
      if (getUserresult?.status === 200) {
        localStorage.setItem('token', token);
        const userRole = signUpRes?.data?.payload?.data?.cognitoRes?.idToken.payload['given_name'];
        localStorage.setItem('userRole', userRole);
        dispatch(user_information(getUserresult?.data?.payload?.data));
        if (userRole === OWNER) {
          navigate('/home');
        } else if (userRole === ACCOUNTANT) {
          navigate('/accountant');
        } else if (userRole === CLEVEL || userRole === MANAGER) {
          navigate('/approver');
        } else {
          showToast(SOMETHING_WENT_WRONG, 'error');
        }
        setLoading(false);
      } else {
        showToast(SOMETHING_WENT_WRONG, 'error');
      }
    } else {
      showToast(
        signUpRes?.data?.metadata?.message || signUpRes?.response?.data?.metadata?.message,
        'error'
      );
      localStorage.clear();
      dispatch(get_tabs({}));
      role === MANAGER || role === CLEVEL || role === ACCOUNTANT ? navigate('/') : setSignIn(false);
    }
  };
  const onSubmit = (values) => {
    handleSubmit(values);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema
  });

  const verifyAccount = async () => {
    setLoading(true);
    const hashedEmail = hashEmail(name?.toLowerCase());
    const params = {
      ConfirmationCode: verficationCode,
      Username: hashedEmail
    };
    const confirmPasswordResult = await confirmSignup(params);
    if (confirmPasswordResult?.status === 200) {
      const { hashedEmail, hashedPassword } = hashEmailAndPassword(name?.toLowerCase(), pass);
      const customerData = {
        email: hashedEmail,
        password: hashedPassword,
        userName: verfiedUser,
        companyId: role === 'Owner' ? '' : company,
        phoneNumber: phoneFormat,
        role: role,
        companyName: companyName,
        name: role === 'Owner' ? 'AP Person' : designationName
      };
      const signUpRes = await customerSignup(customerData);

      if (signUpRes?.status === 200) {
        if (Environment === 'Production') {
          const emailres = emailRes();
        } else {
          ('');
        }
        localStorage.clear();
        dispatch(get_tabs({}));
        dispatch(sign_In(signUpRes?.data?.payload?.data));
        const token = signUpRes?.data?.payload?.data?.cognitoRes?.idToken?.jwtToken;
        const setHeaders = () => {
          const headerConfig = {
            headers: { Authorization: 'Bearer ' + token }
          };
          return headerConfig;
        };
        const getUserresult = await getInfo(
          signUpRes?.data?.payload?.data?.cognitoRes?.accessToken?.payload?.username,
          setHeaders
        );
        if (getUserresult?.status === 200) {
          localStorage.setItem('token', token);
          const userRole =
            signUpRes?.data?.payload?.data?.cognitoRes?.idToken.payload['given_name'];
          localStorage.setItem('userRole', userRole);
          dispatch(user_information(getUserresult?.data?.payload?.data));
          if (userRole === OWNER) {
            navigate('/home');
          } else if (userRole === ACCOUNTANT) {
            navigate('/accountant');
          } else if (userRole === CLEVEL || role === MANAGER) {
            navigate('/approver');
          } else {
            showToast(SOMETHING_WENT_WRONG, 'error');
          }
          setLoading(false);
        } else {
          showToast(SOMETHING_WENT_WRONG, 'error');
        }
      } else {
        showToast(
          signUpRes?.data?.metadata?.message || signUpRes?.response?.data?.metadata?.message,
          'error'
        );
        localStorage.clear();
        dispatch(get_tabs({}));
        role === MANAGER || role === CLEVEL || role === ACCOUNTANT
          ? navigate('/')
          : setSignIn(false);
      }
      setLoading(false);
    } else {
      showToast(confirmPasswordResult?.data?.payload?.data?.cognitoError?.message, 'error');
      setLoading(false);
    }
  };
  const handleResendCode = async () => {
    const newhashEmail = hashEmail(name?.toLowerCase());
    var params = {
      email: newhashEmail
    };
    const resendPasswordApiResult = await resendCode(params);
    if (!isResendEnabled) {
      setCounter(!counter);
      if (resendPasswordApiResult?.status === 200) {
        showToast(IS_CODE_SENT, 'success');
        setIsResendEnabled(true);
        setShowCountdownTimer(false);
        setShowResendCode(false);
      } else {
        showToast(resendPasswordApiResult?.data?.payload?.data?.cognitoError?.message, 'error');
        setIsSignUp(true);
      }
    } else {
      showToast(TIME_ERROR, 'error');
    }
  };
  const Completed = () => {
    setIsResendEnabled(false);
    setShowCountdownTimer(false);
    setShowResendCode(true);
  };
  const renderer = ({ seconds }) => {
    return (
      <div
        style={{
          paddingTop: '2px',
          width: '30px',
          textAlign: 'center',
          fontSize: '1rem',
          fontWeight: 'bold'
        }}>
        <span>{seconds}</span>
      </div>
    );
  };
  const startDate = React.useRef(Date.now());

  return (
    <ThemeProvider theme={theme}>
      <Grid Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}>
          {isSignUp ? (
            <Box component="form" noValidate onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                variant="standard"
                value={formik.values.userName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type={USER}
                id={USER}
                placeholder={NAME_PLACEHOLDER}
                name={USER}
                autoComplete="user-name"
              />
              {formik.touched.userName && formik.errors.userName ? (
                <Box sx={{ color: 'red', margin: '0.1rem 0' }}>{formik.errors.userName}</Box>
              ) : (
                <Box sx={{ whiteSpace: 'pre', margin: '0.1rem 0' }}> </Box>
              )}
              <TextField
                fullWidth
                sx={{ mt: 0.5 }}
                variant="standard"
                value={formik.values.email}
                type={EMAIL}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id={EMAIL}
                placeholder={WORK_EMAIL_PLACEHOLDER}
                name={EMAIL}
                autoComplete={EMAIL}
                InputProps={{
                  readOnly: defaultMail ? true : false
                }}
              />
              {formik.touched.email && formik.errors.email ? (
                <Box sx={{ color: 'red', margin: '0.1rem 0' }}>{formik.errors.email}</Box>
              ) : (
                <Box sx={{ whiteSpace: 'pre', margin: '0.1rem 0' }}> </Box>
              )}
              <Input
                fullWidth
                variant="standard"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name={PASSWORD}
                placeholder={PASSWORD_PLACEHOLDER}
                autoComplete="new-password"
                id={PASSWORD}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formik.touched.password && formik.errors.password && (
                <Box sx={{ color: 'red', margin: '0.1rem 0' }}>{formik.errors.password}</Box>
              )}
              <Input
                fullWidth
                variant="standard"
                sx={{ mt: 2.5 }}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="confirmPassword"
                placeholder="Confirm Password*"
                autoComplete="new-password"
                id="confirmPassword"
                type={showConfirmPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}>
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                <Box sx={{ color: 'red', margin: '0.1rem 0' }}>{formik.errors.confirmPassword}</Box>
              ) : (
                <Box sx={{ whiteSpace: 'pre', margin: '0.1rem 0' }}> </Box>
              )}
              <MuiPhoneNumber
                sx={{ marginBottom: '1rem' }}
                fullWidth
                variant="standard"
                label="Phone Number"
                defaultCountry={'us'}
                disableDropdown={false}
                onChange={(e) => setPhone(e)}
              />

              <TextField
                sx={{ mb: 2.5 }}
                fullWidth
                InputProps={{
                  readOnly: defaultCompany ? true : false
                }}
                variant="standard"
                value={companyName}
                onChange={(e) => handleCompanyNameChange(e)}
                placeholder="Company Name*"
              />

              <Btn
                type={SUBMIT}
                color="#fff"
                variant="outlined"
                disabled={
                  !(formik.isValid && formik.dirty) ||
                  phone === '' ||
                  phone.length < 10 ||
                  loading ||
                  companyName === '' ||
                  companyName?.trim() === ''
                }
                text={CREATE_ACCOUNT}
                loading={loading}
                marginTop=".5rem"
                radius="7.93px"
                setIsHovered={setIsHovered}
              />
              <Grid container justifyContent="center" sx={{ marginTop: '1rem' }}>
                <Box>
                  <Typography
                    sx={{ color: '#9F9F9F', cursor: 'pointer' }}
                    variant="body2"
                    onClick={() => {
                      role === 'Manager' || role === 'C_Level' || role === 'Accountant'
                        ? navigate('/')
                        : setSignIn(true);
                    }}>
                    {ALREADY_REGISTERED}{' '}
                    <span style={{ color: Colors.TOURQUISE }}>
                      <b>{SIGNIN_HEADING}</b>
                    </span>
                  </Typography>
                </Box>
              </Grid>
            </Box>
          ) : (
            <Box
              component="form"
              noValidate
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}>
              <Hidden mdDown>
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: 'center',
                    marginBottom: '1rem',
                    color: Colors.TEXTGREY
                  }}></Typography>
                <Typography
                  variant="body1"
                  sx={{ marginBottom: '3rem', textAlign: 'center', color: Colors.TEXTGREY }}>
                  {EMAIL_NOTE}
                </Typography>
              </Hidden>
              <Hidden mdUp>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 'bold', marginBottom: '1rem', color: Colors.TEXTGREY }}>
                  {VERIFY_ACCOUNT}
                </Typography>
                <Typography
                  variant="p"
                  sx={{ marginBottom: '2rem', textAlign: 'center', color: Colors.TEXTGREY }}>
                  {EMAIL_NOTE}
                </Typography>
              </Hidden>

              <TextField
                inputProps={{ maxLength: 6 }}
                sx={{ marginBottom: '3rem' }}
                fullWidth
                value={verficationCode}
                type="text"
                onChange={(e) => codeValueValidation(e.target.value)}
                name={VERIFICATION_CODE}
                placeholder={VERIFICATION_CODE}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && e.target.tagName === 'INPUT') {
                    e.preventDefault();
                    verifyAccount();
                  }
                }}
              />
              <Btn
                color="#fff"
                variant="contained"
                onClick={verifyAccount}
                disabled={verficationCode.length < 6 || loading}
                text={CONFIRM}
                loading={loading}
                radius="7.93px"
                setIsHovered={setIsHovered}
                buttonText="verify"
              />
              <Grid container sx={{ justifyContent: 'flex-end' }}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: showCountdownTimer ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <span
                    style={{
                      display: 'flex',
                      marginTop: '10px',
                      color: '#9F9F9F !important'
                    }}>
                    <Typography style={{ display: 'inline-flex', color: Colors.TEXTGREY }}>
                      {RESEND_CODE}
                    </Typography>
                    <div style={{ color: Colors.TOURQUISE }}>
                      <Countdown
                        date={startDate.current + 30000}
                        renderer={renderer}
                        key={counter}
                        onComplete={Completed}
                      />
                    </div>
                    <Typography style={{ color: Colors?.TEXTGREY }}>{SECONDS}</Typography>
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <a
                    style={{
                      display: showResendCode ? 'flex' : 'none',
                      color: Colors.TOURQUISE,
                      cursor: 'pointer',
                      justifyContent: 'end',
                      marginTop: '10px'
                    }}
                    onClick={handleResendCode}>
                    {RESEND_CODE_LINK}
                  </a>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Grid>
    </ThemeProvider>
  );
}
