import moment from 'moment';
import { encrypt } from 'n-krypta';
import { baseUrl, NETWORK_ERROR, REACT_APP_KEY } from '../constants/appConstants';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InputBase from '@mui/material/InputBase';
import {
  InsertDriveFileOutlined,
  FindInPageOutlined,
  CorporateFare,
  Business,
  CloseOutlined,
  PanToolOutlined,
  DashboardOutlined,
  EmailOutlined,
  PersonOutlineOutlined,
  Pages,
  HighlightAltOutlined,
  AssignmentLateOutlined,
  Attachment,
  CallReceivedOutlined,
  DescriptionOutlined
} from '@mui/icons-material';
import { styled } from '@mui/material';

import { Colors } from '../config/default';
import MuiModal from '../components/muiModel';
import { isEmpty } from 'lodash';

const BASE_URL = baseUrl();
function buildApiEndpoint(path) {
  const url = new URL(path, BASE_URL);
  return url.href;
}

export const request = async (
  path,
  method = 'GET',
  body = {},
  contentType = 'application/json'
) => {
  const fetchOption = {
    method,

    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': contentType
    }),
    ...(method !== 'GET' ? { body: JSON.stringify(body) } : {})
  };
  try {
    const res = await fetch(buildApiEndpoint(path), fetchOption);

    if (res.status === 200) {
      const jsonResponse = await res.json();
      return jsonResponse;
    } else {
      return res;
    }
  } catch (err) {
    alert(NETWORK_ERROR);
    throw new Error(err);
  }
};
export const setHeaders = () => {
  const headerConfig = {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  };
  return headerConfig;
};
export const setSignOutHeaders = () => {
  const headerConfig = {
    headers: { Accesstoken: 'Bearer ' + localStorage.getItem('accessToken') }
  };
  return headerConfig;
};

export const setERPType = () => {
  const headerConfig = {
    headers: { ErpType: localStorage.getItem('erpType') }
  };
  return headerConfig;
};
export function formatDateOnly(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${month}-${day}-${year}`;
}
export const formatDate = (dateStr) => {
  if (!dateStr || dateStr === '-') {
    return '-';
  }
  const date = moment(
    dateStr,
    ['DD/MM/YYYY', 'D MMMM YYYY', moment.ISO_8601, 'ddd, DD MMM YYYY HH:mm:ss [GMT]'],
    true
  );
  if (date.isValid()) {
    return date.local().format('MM/DD/YYYY, h:mm A');
  } else {
    return '-';
  }
};
export const formatDateWithoutTime = (dateStr) => {
  if (!dateStr || dateStr === '-') {
    return '-';
  }
  const date = moment(
    dateStr,
    ['DD/MM/YYYY', 'D MMMM YYYY', moment.ISO_8601, 'ddd, DD MMM YYYY HH:mm:ss [GMT]'],
    true
  );
  if (date.isValid()) {
    return date.format('MM/DD/YYYY');
  } else {
    return '-';
  }
};
export const hashEmailAndPassword = (email, password) => {
  const salt = REACT_APP_KEY;
  const hashedEmail = encrypt(email, salt);
  const hashedPassword = encrypt(password, salt);
  return { hashedEmail, hashedPassword };
};

export const hashPassword = (password) => {
  const salt = REACT_APP_KEY;
  const newPass = encrypt(password, salt);
  return newPass;
};

export const hashEmail = (email) => {
  const salt = REACT_APP_KEY;
  const newEmail = encrypt(email, salt);
  return newEmail;
};
export const parentTabs = [
  {
    name: 'Dashboard',
    icon: <DashboardOutlined />
  },
  {
    name: 'Documents',
    icon: <InsertDriveFileOutlined />
  },
  { name: 'Integrations', icon: <CorporateFare /> },
  { name: 'Policy', icon: <FindInPageOutlined /> },
  { name: 'Attachments', icon: <Attachment /> }
];
export const childTabs = [
  { name: 'To Review' },
  { name: 'Email' },
  { name: 'Rejected' },
  { name: 'Exported' }
];
export const vendorChildTabs = [{ name: 'All Documents' }];
export const approvalChildTabs = [
  { name: 'To Review' },
  { name: 'On Hold' },
  { name: 'Rejected' },
  { name: 'Exported' }
];

export const usersChildTabs = [{ name: 'Users' }];
export const featuresTabs = [{ name: 'Policy' }];
export const dashboardTabs = [{ name: 'Overview' }];
export const attachmentsTabs = [{ name: 'Documents' }];

export const clientParentTabs = [
  {
    name: 'Dashboard',
    icon: <DashboardOutlined />
  },
  {
    name: 'Documents',
    icon: <InsertDriveFileOutlined />
  }
];

export const formatLineItems = (lineItem) => {
  const formatted = lineItem?.map((item) => {
    const amountString = item?.find((e) => e.name === 'Amount')?.value;
    const amount = amountString.includes(',') ? amountString.replace(/,/g, '') : amountString;
    const description = item?.find((e) => e.name === 'Description')?.value;
    const qty = item?.find((e) => e.name === 'Quantity')?.value;
    return { amount, description, qty };
  });
  return formatted;
};

export const arrangedLineitems = (lineItem) => {
  const arrangedLineitemsArray = lineItem?.map((subArray) => {
    const codeObj = subArray?.find((obj) => obj?.name === 'Product_Code');
    const descriptionObj = subArray?.find((obj) => obj?.name === 'Description');
    const unitPriceObj = subArray?.find((obj) => obj?.name === 'Unit_Price');
    const quantityObj = subArray?.find((obj) => obj?.name === 'Quantity');
    const amountObj = subArray?.find((obj) => obj?.name === 'Amount');
    const glAccountObj = subArray?.find((obj) => obj?.name === 'GlAccount');
    const JobIdObj = subArray?.find((obj) => obj?.name === 'userJobId');
    const phaseObj = subArray?.find((obj) => obj?.name === 'userPhases');
    const costIdObj = subArray?.find((obj) => obj?.name === 'userCostId');
    const costTypeObj = subArray?.find((obj) => obj?.name === 'userCostType');
    const equipmentObj = subArray?.find((obj) => obj?.name === 'equipmentNo');
    const serviceObj = subArray?.find((obj) => obj?.name === 'serviceCode');

    return [
      codeObj,
      descriptionObj,
      unitPriceObj,
      quantityObj,
      amountObj,
      glAccountObj,
      JobIdObj,
      phaseObj,
      costIdObj,
      costTypeObj,
      equipmentObj,
      serviceObj
    ];
  });
  return arrangedLineitemsArray;
};
export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 220,
    backgroundColor: Colors.Canvas_BG,
    color: Colors.WHITE
  }
}));

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: '7px',
    position: 'relative',

    paddingLeft: '1em',
    '&:focus': {
      borderRadius: '7px',
      borderColor: 'none !important'
    }
  }
}));

export const buttonArray = [
  {
    name: 'Reject',
    status: 'Reject',
    icon: <CloseOutlined sx={{ width: { xs: '13px', lg: '21px' }, color: Colors.RED }} />,
    Color: Colors.RED
  },

  {
    name: 'On Hold',
    status: 'on Hold',
    icon: <PanToolOutlined sx={{ width: { xs: '13px', lg: '17px' }, color: Colors.YELLOW }} />,
    Color: Colors.YELLOW
  }
];
export const convertTime = (inputTime) => {
  const match = inputTime.match(/(\d+)h?:?(\d*)min?:?(\d*)sec?/);
  if (!match) {
    return 'Invalid input format';
  }
  const [, hours, minutes, seconds] = match.map((part) => (part === '' ? 0 : Number(part)));
  const totalSeconds = hours * 3600 + minutes * 60 + seconds;
  const resultHours = Math.floor(totalSeconds / 3600);
  const resultMinutes = Math.floor((totalSeconds % 3600) / 60);
  const resultSeconds = totalSeconds % 60;
  let result = '';
  if (resultHours > 0) {
    result += `${resultHours}h`;
  }
  if (resultMinutes > 0) {
    result += ` ${resultMinutes}min`;
  }
  if (resultSeconds > 0) {
    result += ` ${resultSeconds}sec`;
  }
  return result;
};

export const AdminParentTabs = [
  {
    name: 'Documents',
    icon: <InsertDriveFileOutlined sx={{ display: { xs: 'none', md: 'block' } }} />
  },
  {
    name: 'Users',
    icon: <PersonOutlineOutlined sx={{ display: { xs: 'none', md: 'block' } }} />
  },
  { name: 'Vendors', icon: <CorporateFare sx={{ display: { xs: 'none', md: 'block' } }} /> }
];
export const AdminChildTabs = [
  {
    name: 'All Users'
  },
  {
    name: 'Manual Users'
  }
];
export const AdminDocChildTabs = [
  {
    name: 'Error Documents'
  }
];
export const AdminVendorsChildTabs = [
  {
    name: 'All Vendors'
  }
];
export const userColumns = [
  {
    field: 'Username',
    headerName: (
      <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
        <PersonOutlineOutlined
          sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
        />
        Username
      </div>
    ),
    flex: 1,
    minWidth: 240,
    sortable: false
  },
  {
    field: 'email',
    headerName: (
      <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
        <EmailOutlined sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }} />
        Email
      </div>
    ),
    flex: 1,
    minWidth: 240,
    sortable: false
  },
  {
    field: 'role',
    headerName: (
      <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
        <PersonOutlineOutlined
          sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
        />
        Role
      </div>
    ),
    flex: 1,
    minWidth: 200,
    sortable: false
  },
  {
    field: 'company',
    headerName: (
      <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
        <Business sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }} />
        Company Name
      </div>
    ),
    flex: 1,
    minWidth: 200,
    sortable: false
  },
  {
    field: 'invoices',
    headerName: (
      <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
        <InsertDriveFileOutlined
          sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '6px' }}
        />
        No of Invoices
      </div>
    ),
    flex: 1,
    minWidth: 200,
    sortable: false
  },
  {
    field: 'pages',
    headerName: (
      <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
        <Pages sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }} />
        No of Pages
      </div>
    ),
    flex: 1,
    minWidth: 150,
    sortable: false
  },
  {
    field: 'action',
    headerName: (
      <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
        <HighlightAltOutlined
          sx={{
            color: Colors.TOURQUISE,
            fontSize: '17px',
            marginRight: '4px'
          }}
        />
        Actions
      </div>
    ),
    flex: 1,
    minWidth: 150,
    sortable: false,
    disablecolumnmenu: false,
    renderCell: (params) => (
      <MuiModal
        text="create bill"
        disable={params?.row?.invoices === 0}
        data={params?.row?.email}
        color={Colors.TOURQUISE}
        btnHeight="2rem"
        width="6rem"
      />
    )
  }
];

export const vendorColumns = [
  {
    field: 'email',
    headerName: (
      <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
        <EmailOutlined sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }} />
        Email
      </div>
    ),
    flex: 1,
    minWidth: 240,
    sortable: false
  },
  {
    field: 'invoices',
    headerName: (
      <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
        <InsertDriveFileOutlined
          sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
        />
        No of Invoices
      </div>
    ),
    flex: 1,
    minWidth: 200,
    sortable: false
  },
  {
    field: 'pages',
    headerName: (
      <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
        <Pages sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }} />
        No of Pages
      </div>
    ),
    flex: 1,
    minWidth: 150,
    sortable: false
  },
  {
    field: 'limit',
    headerName: (
      <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
        <AssignmentLateOutlined
          sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
        />
        Document Limit
      </div>
    ),
    flex: 1,
    minWidth: 150,
    sortable: false
  },
  {
    field: 'action',
    headerName: (
      <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
        <HighlightAltOutlined
          sx={{
            color: Colors.TOURQUISE,
            fontSize: '17px',
            marginRight: '4px'
          }}
        />
        Actions
      </div>
    ),
    flex: 1,
    minWidth: 240,
    sortable: false,
    disablecolumnmenu: false,
    renderCell: (params) => (
      <>
        <MuiModal
          text="Create Bill"
          disable={params?.row?.invoices === 0}
          data={params?.row?.email}
          show="createBillVendor"
          color={Colors.TOURQUISE}
          btnHeight="2rem"
          width="5rem"
        />
      </>
    )
  },
  {
    flex: 1,
    minWidth: 240,
    sortable: false,
    disablecolumnmenu: false,
    renderCell: (params) => (
      <>
        <MuiModal
          text="View"
          data={params?.row?.documents}
          show="vendorsDocuments"
          color={Colors.TOURQUISE}
          btnHeight="2rem"
          width="5rem"
          disable={params?.row?.documents?.length === 0}
          mr=".5rem"
        />
        <MuiModal
          text="Edit"
          data={params?.row}
          show="editVendor"
          color={Colors.TOURQUISE}
          btnHeight="2rem"
          width="5rem"
        />
      </>
    )
  }
];

export const getPosition = (id, getRef) => {
  // get on-screen position of highlighted boxes
  const reference = getRef(id);
  const object = reference?.current?.getClientRects()[0];
  if (object) {
    const position = {
      width: object.width,
      height: object.height,
      left: object.left,
      top: object.top - 67
    };
    return position;
  } else {
    return null;
  }
};

export const filterDocuments = (queue) => {
  const newQueue = queue?.filter(
    (doc) =>
      doc.documentStatus === 'Populated' ||
      doc.documentStatus === 'on Hold' ||
      doc.documentStatus === 'Reject' ||
      (doc.documentStatus === 'Approved' && doc.erpQbdStatus === 'Error')
  );
  return newQueue;
};

export function replaceName(name, erpType) {
  switch (name) {
    case 'Product_Code':
      return 'Product Code';
    case 'Unit_Price':
      return 'Unit Price';
    case 'userCostId':
      return 'Cost Id';
    case 'userCostType':
      return erpType === 'QuickBooksOnline' ? 'Class' : 'Cost Type';
    case 'userPhases':
      return 'Phase';
    case 'userJobId':
      return erpType === 'QuickBooksOnline' ? 'Project Id' : 'Job Id';
    case 'GlAccount':
      return 'Gl Account';
    case 'equipmentNo':
      return 'Equipment No';
    case 'serviceCode':
      return 'Service Code';
    default:
      return name;
  }
}

export function getYearRange() {
  const currentYear = new Date().getFullYear();
  const prevYear = currentYear - 1;
  const prev2Year = currentYear - 2;
  const nextYears = [currentYear + 1, currentYear + 2];
  return [prev2Year, prevYear, currentYear, ...nextYears];
}

export function isvendorValueMatching(array, value) {
  if (!Array.isArray(array)) {
    return false;
  }
  const sanitize = (str) => str?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
  for (const item of array) {
    if (sanitize(item?.name) === sanitize(value)) {
      return true;
    }
  }

  return false;
}
export function getVendorNameLength(erpType) {
  let vendorNameLength;
  switch (erpType) {
    case 'Foundation':
      vendorNameLength = 30;
      break;
    default:
      vendorNameLength = 41;
      break;
  }
  return vendorNameLength;
}

export function getTodaysDate() {
  const today = new Date();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const year = today.getFullYear();
  return `${month}/${day}/${year}`;
}
export const deepEqual = (obj1, obj2) => {
  if (typeof obj1 === 'object' && obj1 !== null && typeof obj2 === 'object' && obj2 !== null) {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }
    for (let key in obj1) {
      if (!deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  } else {
    return obj1 === obj2;
  }
};
export const truncateFileName = (fileName, maxLength) => {
  if (!fileName) return '';
  const dotIndex = fileName?.lastIndexOf('.');
  if (dotIndex === -1) return fileName;
  const name = fileName?.substring(0, dotIndex);
  const extension = fileName?.substring(dotIndex);
  if (name?.length > maxLength) {
    return name?.substring(0, maxLength) + '...' + extension;
  }
  return fileName;
};
export const formatNumber = (number) => {
  if (isNaN(number) || number === null || number === undefined || number === '' || number === '-') {
    return number;
  }

  const formattedNumber = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(number);

  return formattedNumber;
};

export function formatNumberWithoutDecimal(number) {
  if (isNaN(number) || number === null || number === undefined || number === '' || number === '-') {
    return number;
  }

  const numStr = number?.toString();
  const length = numStr?.length;
  if (length <= 3) {
    return numStr;
  }

  return numStr?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const InvoiceNumberKeys = [
  'invoice_number',
  'application_no',
  'receipt_number',
  'Estimate_no',
  'Po_number',
  'DeliveryTicket_no'
];
const createNewLineItem = (
  headers,
  total,
  jobId,
  costId,
  serviceCode,
  phase,
  equipmentNo,
  costType,
  glAccount
) => {
  const values = {
    Description: 'Consolidated Items',
    Amount:
      total !== '' && total !== '-' && total !== 'N/A' ? parseFloat(total)?.toFixed(2) : total,
    userJobId: jobId,
    userCostId: costId,
    serviceCode: serviceCode,
    userPhases: phase,
    equipmentNo: equipmentNo,
    userCostType: costType,
    GlAccount: glAccount
  };

  return Array.from({ length: headers?.length || 0 }, (_, index) => {
    const headerName = headers[index];
    if (headerName) {
      return {
        name: headerName,
        value: values[headerName] ?? '', // Populate the corresponding value
        width: null,
        height: null,
        left: null
      };
    }
    return null;
  });
};
export const consolidateItems = (
  items,
  total,
  codes,
  costId,
  jobId,
  glAccount,
  phase,
  costType,
  serviceCode,
  equipmentNo
) => {
  if (!isEmpty(items)) {
    const itemValues = {
      Description: 'Consolidated Items',
      Amount:
        total !== '' && total !== '-' && total !== 'N/A' ? parseFloat(total)?.toFixed(2) : total,
      userJobId: jobId,
      userCostId: costId,
      serviceCode: serviceCode,
      userPhases: phase,
      equipmentNo: equipmentNo,
      userCostType: costType,
      GlAccount: glAccount
    };
    return items[0]?.map((item) => {
      const value = itemValues[item?.name] ?? '';
      return {
        ...item,
        value,
        width: '',
        height: '',
        left: ''
      };
    });
  } else {
    const newLineItem = createNewLineItem(
      codes,
      total,
      jobId,
      costId,
      serviceCode,
      phase,
      equipmentNo,
      costType,
      glAccount
    )?.filter(Boolean);
    return newLineItem;
  }
};
export const resetObjFields = (obj) => {
  const newObj = {};
  Object?.keys(obj)?.forEach((key) => {
    newObj[key] = [];
  });
  return newObj;
};
