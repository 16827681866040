import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Paper, Grid, Box, Modal, Button, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import { Close, KeyboardBackspace, ContentCopy, Check } from '@mui/icons-material';

import Basic from './dropzone';
import { dropzoneData } from '../constants/appConstants';
import SplitDoc from './splitDoc';
import theme from '../theme';
import { Colors } from '../config/default';
import PermissionRadio from './permissionRadio';
import { HtmlTooltip } from '../services/common';
import Btn from '../components/button';
import { InvoiceEmail } from '../constants/appConstants';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '20px !important'
};

export default function UploadFileButton({
  icon,
  text,
  borderColor,
  uploadPdfScreen,
  uploadDocScreen,
  setUploadPdfScreen,
  setUploadDocScreen,
  setOpen,
  open,
  onClick,
  tooltipText
}) {
  const [files, setFiles] = React.useState([]);
  const [permission, setPermission] = useState('Invoice');
  const [splitDocData, setSplitDocDaata] = useState('');
  const [splitDoc, setSplitDoc] = useState(false);
  const userDetails = useSelector((state) => state?.userInfo?.userInfo?.user);
  const isG702 = userDetails?.G702;
  const isReceipt = userDetails?.receiptUpload;
  const deliveryTicket = userDetails?.deliveryTicketUpload;
  const [copySuccess, setCopySuccess] = useState(false);
  const emailRef = useRef(null);

  let options = ['Invoice'];
  if (
    (userDetails?.erpType === 'Foundation' || userDetails?.erpType === 'QuickBooksDesktop') &&
    userDetails?.quotePermission
  ) {
    options.push('Quote');
  }
  if (isG702) {
    options.push('G702');
  }
  if (isReceipt) {
    options.push('Receipt');
  }
  if (deliveryTicket) {
    options.push('Delivery Ticket');
  }
  const { SELECT_TYPE } = dropzoneData;
  const removeFile = (fileName) => {
    const filteredFile = files.filter((file) => file.name !== fileName);
    setFiles(filteredFile);
  };
  useEffect(() => {}, [files]);

  const handleClose = () => {
    setOpen(false);
    setUploadPdfScreen(false);
    setUploadDocScreen(false);
    setSplitDoc(false);
    setFiles([]);
    setPermission('Invoice');
  };
  const handleCopyEmail = () => {
    const emailText = emailRef.current.textContent;
    navigator.clipboard
      .writeText(emailText)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 1000);
      })
      .catch((err) => {
        return err;
      });
  };
  return (
    <ThemeProvider theme={theme}>
      <div>
        <HtmlTooltip
          title={
            <Typography color="inherit" sx={{ fontSize: '1em' }}>
              {tooltipText ? tooltipText : ''}
            </Typography>
          }>
          <Button
            variant="outlined"
            onClick={onClick}
            sx={{
              textTransform: 'unset !important',
              marginRight: { sm: '1rem' },
              width: { xs: '4.5rem', sm: '9rem' },
              height: { xs: '2rem', sm: '2.2rem' },
              color: Colors.WHITE,
              borderRadius: '10px',
              fontSize: { xs: '.65rem', sm: '.875rem' },
              padding: { xs: 0, sm: 'auto' },
              borderColor: borderColor ? borderColor : '',
              '&:hover': {
                borderColor: borderColor ? borderColor : '',
                backgroundColor: borderColor ? 'rgba(196, 75, 108, 0.08)' : ''
              }
            }}>
            {icon}
            {text}
          </Button>
        </HtmlTooltip>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ borderRadius: '20px !important' }}>
          <Box
            sx={{
              ...style,
              width: { xs: '90vw', lg: splitDoc ? '61vw' : '50vw' }
            }}>
            <Paper
              elevation={5}
              sx={{
                height: 'max-content',
                borderRadius: '20px !important'
              }}>
              <Grid
                container
                sx={{
                  borderTopRightRadius: '20px !important',
                  backgroundColor: uploadDocScreen && Colors.NAV_BLACK,
                  height: uploadDocScreen && '150px',
                  borderTopLeftRadius: '20px !important'
                }}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: splitDoc ? 'space-between' : 'flex-end',
                    padding: '0em 1em'
                  }}>
                  {splitDoc ? (
                    <KeyboardBackspace
                      sx={{ color: Colors.TEXTGREY, marginTop: '1em', cursor: 'pointer' }}
                      onClick={() => {
                        setSplitDoc(false), setUploadPdfScreen(true);
                      }}
                    />
                  ) : (
                    ''
                  )}

                  <Close
                    sx={{ color: Colors.TEXTGREY, marginTop: '1em', cursor: 'pointer' }}
                    onClick={handleClose}
                  />
                </Grid>
                {uploadDocScreen && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'center'
                    }}>
                    <Typography sx={{ fontSize: '15px', mb: 1 }}>
                      Upload by sending email to Beiing Human
                    </Typography>
                    <Grid item xs={12} sx={{ mb: 2, display: 'flex' }}>
                      <div
                        ref={emailRef}
                        style={{
                          border: 'none',
                          color: Colors.TEXTGREY,
                          backgroundColor: Colors.Canvas_BG,
                          height: '1.2rem',
                          padding: '.5rem',
                          width: 'max-content',
                          fontSize: '.8rem',
                          borderRadius: '10px',
                          marginRight: '.5rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                        {InvoiceEmail()}
                      </div>
                      <Btn
                        text={copySuccess ? 'Copied!' : 'Copy Email'}
                        backgroundColor={Colors.DEEPBLUE}
                        radius="10px"
                        color={Colors.WHITE}
                        startIcon={
                          copySuccess ? (
                            <Check sx={{ marginLeft: '.5rem' }} />
                          ) : (
                            <ContentCopy sx={{ marginLeft: '.5rem' }} />
                          )
                        }
                        height="2.1rem"
                        FONT=".8rem"
                        width="8rem"
                        onClick={handleCopyEmail}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {uploadDocScreen ? (
                <Grid container sx={{ height: '500px' }}>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{ flexDirection: 'column', justifyContent: 'center', marginTop: '1rem' }}>
                    <PermissionRadio
                      permission={permission}
                      setPermission={setPermission}
                      question={SELECT_TYPE}
                      options={options}
                    />
                  </Grid>
                  <Basic
                    files={files}
                    setFiles={setFiles}
                    setOpen={setOpen}
                    removeFile={removeFile}
                    permission={permission}
                    setPermission={setPermission}
                    multiple={true}
                    isG702={isG702}
                    icon={<CloudUploadIcon sx={{ fontSize: 60, color: Colors.DARKBLUE }} />}
                    uploadDocScreen={uploadDocScreen}
                    setSplitDoc={setSplitDoc}
                    setUploadPdfScreen={setUploadPdfScreen}
                    setSplitDocDaata={setSplitDocDaata}
                  />
                </Grid>
              ) : uploadPdfScreen ? (
                <Grid container sx={{ height: '480px' }}>
                  <Basic
                    files={files}
                    setFiles={setFiles}
                    setOpen={setOpen}
                    removeFile={removeFile}
                    permission={permission}
                    setPermission={setPermission}
                    multiple={true}
                    isG702={isG702}
                    icon={<InsertPageBreakIcon sx={{ fontSize: 60, color: Colors.DARKBLUE }} />}
                    uploadDocScreen={uploadDocScreen}
                    setSplitDoc={setSplitDoc}
                    setUploadPdfScreen={setUploadPdfScreen}
                    setSplitDocDaata={setSplitDocDaata}
                    accept="pdf"
                  />
                </Grid>
              ) : splitDoc ? (
                <SplitDoc
                  setSplitDoc={setSplitDoc}
                  setUploadPdfScreen={setUploadPdfScreen}
                  handleClose={handleClose}
                  splitDocData={splitDocData}
                  setOpen={setOpen}
                />
              ) : (
                ''
              )}
            </Paper>
          </Box>
        </Modal>
      </div>
    </ThemeProvider>
  );
}
