import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Colors } from '../config/default';
import { duplicateDoc, tableComponent } from '../constants/appConstants';
import { useToast } from '../toast/toastContext';
import {
  getDocumentDetails,
  processDuplicateDocument,
  getSelectedDocs,
  deleteDocument
} from '../services/services';
import '../image.css';
import { get_queue } from '../redux/actions/action';
import Prompt from './prompt';

export default function DuplicateDoc({ handleClose, id, documentName, setOpened }) {
  const smallScreen = useMediaQuery('(max-height:1500px)');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [data, setData] = useState({});
  const { PREVIOUS, NEW, DUPLICATE_DOC, CONTINUE, DELETE } = duplicateDoc;
  const { DELETE_DOCUMENT_MESSAGE, DELETE_DOCUMENT } = tableComponent;
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const callSource = userRole === 'Vendor' ? 'api' : 'app';
  const handleContinue = async () => {
    setProcessing(true);
    const res = await processDuplicateDocument(id);
    if (res?.status === 200) {
      showToast(res?.data?.metadata?.message, 'success');
      handleClose();
      const response = await getSelectedDocs(callSource);
      if (response?.response?.status === 401) {
        localStorage.clear();
        navigate('/');
      }
      dispatch(get_queue(response?.data?.payload?.data));
    } else {
      showToast(res?.response?.data?.metadata?.message || res?.data?.metadata?.message, 'error');
    }
    setProcessing(false);
  };
  const selectedData = async () => {
    const result = await getDocumentDetails(id, callSource);
    if (result?.response?.status === 401) {
      localStorage.clear();
      navigate('/');
    }
    setData(result?.data?.payload?.data);
    setLoading(false);
  };
  useEffect(() => {
    selectedData();
  }, []);
  const deleteDocs = async (id) => {
    setDeleting(true);
    const result = await deleteDocument(id);
    if (result?.status === 200) {
      const response = await getSelectedDocs(callSource);
      setDeleting(false);
      showToast(result?.data?.metadata?.message, 'success');
      setOpen(false);
      setOpened(false);
      if (response?.response?.status === 401) {
        localStorage.clear();
        navigate('/');
      }
      dispatch(get_queue(response?.data?.payload?.data));
    } else {
      showToast(
        result?.response?.data?.metadata?.message || result?.data?.metadata?.message,
        'error'
      );
    }
  };

  const handleDeleteDocument = (e) => {
    e.stopPropagation();
    deleteDocs(id);
  };
  const [isEnlargedArrayPrev, setIsEnlargedArrayPrev] = useState(
    Array(data?.previousDocImageUrl?.length).fill(false)
  );
  const [isEnlargedArrayNew, setIsEnlargedArrayNew] = useState(
    Array(data?.newDocImageUrl?.length).fill(false)
  );
  const handleImageClickPrev = (index) => {
    const updatedEnlargedArrayPrev = [...isEnlargedArrayPrev];
    updatedEnlargedArrayPrev[index] = !updatedEnlargedArrayPrev[index];
    setIsEnlargedArrayPrev(updatedEnlargedArrayPrev);
  };

  const handleImageClickNew = (index) => {
    const updatedEnlargedArrayNew = [...isEnlargedArrayNew];
    updatedEnlargedArrayNew[index] = !updatedEnlargedArrayNew[index];
    setIsEnlargedArrayNew(updatedEnlargedArrayNew);
  };

  return (
    <Grid>
      <div style={{ textAlign: 'center', color: Colors.WHITE, fontSize: '1.25em' }}>
        {DUPLICATE_DOC}
      </div>
      <>
        <Grid container xs={12} sx={{ mt: '1em' }}>
          <Grid item xs={6}>
            <Typography
              sx={{
                fontSize: { xs: '1em', xl: '1.5em' },
                textAlign: 'center',
                color: Colors.LIGHTBLUE
              }}>
              {PREVIOUS}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                fontSize: { xs: '1em', xl: '1.5em' },
                textAlign: 'center',
                color: Colors.LIGHTBLUE
              }}>
              {NEW}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          sx={{
            height: { xs: '60vh', xl: '50vh' },
            overflowY: 'auto',
            margin: '1rem 4rem',
            borderRadius: '10px',
            padding: '0rem 1rem',
            border: '2px dashed #5B5B5B',
            '&::-webkit-scrollbar': {
              width: '0.2em',
              borderRadius: '20px'
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
              outline: '1px solid slategrey'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#fff',
              outline: '1px solid slategrey'
            }
          }}>
          {loading ? (
            <Grid
              container
              sx={{
                height: 'inherit',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <CircularProgress size={100} color="primary" />
            </Grid>
          ) : (
            <Grid sx={{ height: '98%' }} container>
              <Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
                <Grid xs={5}>
                  {data?.previousDocImageFileName?.map((prevDocName) => {
                    return (
                      <Typography
                        sx={{
                          fontSize: { xs: '1em', xl: '1.5em' },
                          mt: '.5em',
                          color: Colors.TEXTGREY,
                          wordBreak: 'break-word'
                        }}>
                        {prevDocName}
                      </Typography>
                    );
                  })}
                </Grid>
                <Grid xs={5}>
                  {data?.newDocImageFileName?.map((newDocName) => {
                    return (
                      <Typography
                        sx={{
                          fontSize: { xs: '1em', xl: '1.5em' },
                          mt: '.5em',
                          color: Colors.TEXTGREY,
                          wordBreak: 'break-word'
                        }}>
                        {newDocName}
                      </Typography>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid container>
                <Grid xs={5}>
                  {data?.previousDocImageUrl?.map((prevImg, prevIndex) => {
                    return (
                      <Grid
                        key={`prevImg_${prevIndex}`}
                        className={`duplicateImage-container ${
                          isEnlargedArrayPrev[prevIndex] ? 'duplicateEnlarged' : ''
                        }`}
                        onClick={() => handleImageClickPrev(prevIndex)}>
                        <img
                          id="i"
                          style={{
                            marginTop: { xs: '.5em', xl: '' },
                            cursor: isEnlargedArrayPrev[prevIndex] ? 'zoom-out' : 'zoom-in',
                            width: isEnlargedArrayPrev[prevIndex] ? 'auto' : '100%',
                            objectFit: 'contain',
                            height: isEnlargedArrayPrev[prevIndex]
                              ? 'auto'
                              : smallScreen
                              ? '50vh'
                              : '40vh'
                          }}
                          src={prevImg}
                          alt="new"
                        />
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid
                  xs={2}
                  sx={{
                    height: { xs: '46vh', xl: '32vh' },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <CompareArrowsIcon sx={{ fontSize: '3em', color: Colors.LIGHTBLUE }} />
                </Grid>
                <Grid xs={5}>
                  {data?.newDocImageUrl?.map((newImg, newIndex) => {
                    return (
                      <Grid
                        key={`newImg_${newIndex}`}
                        className={`duplicateImage-container ${
                          isEnlargedArrayNew[newIndex] ? 'duplicateEnlarged' : ''
                        }`}
                        onClick={() => handleImageClickNew(newIndex)}>
                        <img
                          id="i"
                          style={{
                            marginTop: { xs: '.5em', xl: '' },
                            cursor: isEnlargedArrayNew[newIndex] ? 'zoom-out' : 'zoom-in',
                            width: isEnlargedArrayNew[newIndex] ? 'auto' : '100%',
                            objectFit: 'contain',
                            height: isEnlargedArrayNew[newIndex]
                              ? 'auto'
                              : smallScreen
                              ? '50vh'
                              : '40vh'
                          }}
                          src={newImg}
                          alt="new"
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </>

      <Grid
        container
        xs={12}
        sx={{ justifyContent: 'center', alignItems: 'center', m: '2rem 0em' }}>
        <Button
          variant="outlined"
          onClick={() => setOpen(true)}
          sx={{
            borderColor: Colors.RED,
            color: Colors.WHITE,
            borderRadius: '10px',
            fontSize: '14px',
            textTransform: 'none',
            width: '9rem',
            height: '2.5rem',
            '&:hover': {
              borderColor: Colors.RED,
              backgroundColor: 'transparent'
            }
          }}>
          {DELETE}
        </Button>
        <Prompt
          title={DELETE_DOCUMENT}
          description={DELETE_DOCUMENT_MESSAGE}
          leftButton="Cancel"
          rightButton="Delete"
          deleteContent={documentName}
          setOpen={setOpen}
          open={open}
          opacity={true}
          handleClick={handleDeleteDocument}
          loading={deleting}
        />
        <Button
          onClick={handleContinue}
          disabled={loading || processing}
          variant="outlined"
          sx={{
            borderColor: Colors.LIGHTBLUE,
            fontSize: '14px',
            color: Colors.WHITE,
            borderRadius: '10px',
            ml: '2em',
            textTransform: 'none',
            width: '9rem',
            height: '2.5rem',
            '&:hover': {
              borderColor: Colors.LIGHTBLUE,
              backgroundColor: 'transparent'
            }
          }}>
          {processing ? <CircularProgress size={25} color="inherit" /> : CONTINUE}
        </Button>
      </Grid>
    </Grid>
  );
}
