import Env from '../envConfig/environment.json';
export const Environment = Env.Environment;
export const baseUrl = () => {
  let BASE_URL = '';
  if (Environment === 'Development') {
    BASE_URL = 'https://dev-api-v2.beinghumans.co/';
  } else if (Environment === 'Production') {
    BASE_URL = 'https://api-v2.beiinghuman.com/';
  } else {
    BASE_URL = 'http://localhost:3000/';
  }
  return BASE_URL;
};
export const StripeKey = () => {
  let STRIPE_KEY;
  if (Environment === 'Production') {
    STRIPE_KEY =
      'pk_live_51Nag7hJsKreadjPIAZZFwkIBKQpk4wAPrlHtKwZ2IuHaS1SLITTEczsKu2VFAmDgzvFHWrcaAoNCvSL7htJJV9ww00B3YSC3ME';
  } else {
    STRIPE_KEY =
      'pk_test_51NZrH0C0wgKISC6Qdz5K1tfa7ZzPdrOfIS2tiSlIIMF4gyvh53WtJGRc7BepwUxBaaq6RbzMuvQeRUEUfDBzC9IQ00ZpsChaX4';
  }
  return STRIPE_KEY;
};
export const InvoiceEmail = () => {
  let MAIL;
  if (Environment === 'Production') {
    MAIL = 'process@documents.beiinghuman.com';
  } else {
    MAIL = 'process_dev@documents.beiinghuman.com';
  }
  return MAIL;
};
export const NETWORK_ERROR = 'NETWORK_ERROR';

export const ValidationMessages = {
  REQUIRED: 'This field is required',
  INVALID_EMAIL: 'Invalid email address',
  INVALID_USERNAME: 'User name cannot contain special characters',
  PASSWORD_CHECK:
    'At least 8 characters, including uppercase, lowercase, number and special character',
  INVALID_AMOUNT: 'Amount is invalid. It must not be empty and contain only digits',
  INVALID_PAYMENT: 'CURRENT PAYMENT DUE is invalid. It must not be empty and contain only digits',
  INVALID_RETAINAGE:
    'RETAINAGE PERCENTAGE is invalid. It must not be empty and contain only digits',
  INVALID_DATE: 'Invoice Date is invalid, It must be in mm/dd/yyyy format',
  INVALID_DUE_DATE: 'Due Date is invalid, It must be in mm/dd/yyyy format',
  INVALID_EXPIRATION_DATE: 'Expiration Date is invalid, It must be in mm/dd/yyyy format',
  INVALID_RECEIPT_DATE: 'Receipt Date is invalid, It must be in mm/dd/yyyy format',
  INVALID_ESTIMATE_DATE: 'Quote Date is invalid, It must be in mm/dd/yyyy format',
  INVALID_DT_DATE: 'Delivery Date is invalid, It must be in mm/dd/yyyy format',
  INVALID_PO_DATE: 'PO Date is invalid, It must be in mm/dd/yyyy format',
  INVALID_G702_DATE: 'PERIOD TO is invalid, It must be in mm/dd/yyyy format',
  INVALID_PASSWORD: 'Email Address or Password is incorrect',
  INVALID_VENDOR: 'Vendor name must not be empty',
  INVALID_VENDOR_LENGTH: 'Vendor must not be grearter than 41 characters',
  INVALID_FOUNDATION_VENDOR_LENGTH: 'Vendor must not be grearter than 30 characters',
  INVALID_FOUNDATION_FROM_CONTRACTOR_LENGTH:
    'From contractor must not be grearter than 30 characters',
  INVALID_INVOICENUMBER: 'Invoice number must not be empty',
  INVALID_INVOICELENGTH: 'Invoice number must not be grearter than 20 characters',
  INVALID_RECEIPTNUMBER: 'Receipt number must not be empty',
  INVALID_ESTIMATENUMBER: 'Quote number must not be empty',
  INVALID_PONUMBER: 'Purchase Order must not be empty',
  INVALID_RECEIPTLENGTH: 'Receipt number must not be grearter than 20 characters',
  INVALID_ESTIMATELENGTH: 'Quote number must not be grearter than 20 characters',
  INVALID_POLENGTH: 'Purchase Order must not be grearter than 10 characters',
  INVALID_APPLICATION_LENGTH: 'Application number must not be grearter than 20 characters',
  INVALID_PROJECTNUMBER: 'Application number must not be empty',
  INVALID_CONTRACTOR: 'From contractor must not be empty',
  INVALID_CONTRACTOR_LENGTH: 'From contractor must not be grearter than 41 characters',
  FORGOT_PASSWORD: 'Forgot Password ?',
  CREATE_PASSWORD: 'Create new password',
  INVALID_JOBID: 'Job Id must not be empty',
  INVALID_COSTID: 'Cost Id must not be empty',
  INVALID_GLACCOUNT: 'Gl account must not be empty',
  INVALID_COSTTYPE: 'Cost type must not be empty',
  INVALID_VENDOR_NUMBER: 'Vendor number must not be empty and it must be associated with Vendor',
  HEADING: 'Forget password Being Humans Account',
  INVALID_TOTAL: 'Total Fees is invalid. It must only contain digits',
  INVALID_SUBTOTAL: 'Subtotal is invalid. It must only contain digits',
  PASSWORD_SCHEMA:
    'Password should be at least 8 characters, including uppercase, lowercase, number and special character (except #)',
  VENDOR_NUM_NOT_MATCHED_IN_LIST:
    'Please select the vendor Number from your uploaded list to create PO within your connected ERP',
  INVALID_LINEITEMS_DESCRIPTION_INFO: 'Description must not be empty',
  INVALID_LINEITEMS_AMOUNT_INFO: 'Amount must not be empty',
  INVALID_LINEITEMS_JOBID_INFO: 'Job Id must not be empty',
  INVALID_LINEITEMS_COSTID_INFO: 'Cost Id must not be empty',
  INVALID_LINEITEMS_COSTYPE_INFO: 'Cost Type must not be empty',
  INVALID_LINEITEMS_GLACCOUNT_INFO: 'Gl Account must not be empty',
  INVALID_DELIVERYLENGTH: 'Document number must not be grearter than 20 characters',
  INVALID_DELIVERYNUMBER: 'Document number must not be empty',
  INVALID_LINEITEMS_Quantity_INFO: 'Quantity must not be empty'
};

export const AdminTable = {
  CSV: 'Download CSV',
  XML: 'Download XML',
  ALL_DOCS: 'All Documents',
  SYNC: 'Sync',
  EXPORTABLE_FILE: 'Exportable File',
  SYNC_USER: 'Sync from ERP',
  DOWNLOAD_REPORT: 'Download Report'
};

export const CustomerDataTable = {
  USERNAME: 'Username',
  EMAIL: 'Email',
  USER_ROLE: 'User Role',
  SIGN_UP: 'Sign Up',
  ACTIONS: 'Actions',
  DELETE: 'Delete',
  INVITE_USER: 'Invite a new user to the system.',
  CONFIRM_DELETE_USER: 'Are you sure you want to delete this user',
  CANCEL: 'Cancel'
};

export const Profile = {
  ADD_FOLDER_ID: 'Add Google Drive folder id',
  ADD_PATH: 'Add local path of Documents folder',
  PROFILE_HEADING: 'Profile Details',
  SELECT_ERP: 'Select ERP Option',
  TOTAL_INVOICE: 'Total Documents',
  TOTAL_PAGES: 'Total pages',
  SAVE: 'Save',
  ENABLE_JOB: 'Enable Phase, Cost Type, Job Id & Cost Id',
  G702_PERMISSION: 'Enable G702 Upload',
  PO_MATCHING: 'Enable Purchase Order/ Subcontract Matching',
  DOC_SPLITTER: 'Enable Doc Splitter',
  DUPLICATE_CHECK: 'Enable Duplicate Bill No Check',
  BACK: 'Back',
  BEIING_HUMAN: 'Beiing Human',
  ENABLE_DUPLICATION: 'Allow Duplicate Detection on File Processing',
  DOC_LIMIT: 'Document Limit',
  DISCONNECT_INTEGRATION_MESSAGE: 'Disconnect your previous integration to change ERP type.',
  RECEIPT_UPLOAD: 'Enable Receipt Upload',
  QUOTE_UPLOAD: 'Enable Quote and PO Upload',
  DELIVERY_TICKET_UPLOAD: 'Enable Delivery Ticket Upload',
  SERVICE_AND_EQUIPMENT: 'Enable Service Code and Equipment No',
  DEFAULT_CODE: 'Enable default Coding',
  LINEITEM_TOTAL_MATCHING: 'Lineitems total Matching',
  QUICKBOOKS_NOTFICATION: 'Intuit and QuickBooks are registered trademarks of Intuit Inc.',
  QUICKBOOKS_PERMISSION: ' Used with permission.',
  SAVE_VENDOR_CODE: 'Save Vendor Codes',
  VENDOR_CODE_TOOLTIP: 'Save the coding for each vendor and prefill in the next document',
  ACCURACY_BASED_POINTING: 'Enable Confidence score based Pointing',
  ACCURACY_TOOLTIP: 'Green for 99% and above , Yellow for 85% to 98% , Red for below 85%',
  CONSOLIDATE_LINEITEMS: 'Consolidate Line items'
};

export const dropzoneData = {
  INFO: 'Upload Files',
  SUCCESS: 'SUCCESS',
  FILE_STATUS: 'No File Uploaded',
  UPLOADING_INFO: "Drag 'n' drop files here, or click to select files",
  BTN_TEXT: 'Submit',
  PDF_UPLOADING_INFO: 'Drag and drop a PDF, then split it into multiple files.',
  SPLIT_PDF: 'Split PDF',
  SELECT_TYPE: 'Select the document type'
};

export const protectedSchema = {
  ALREADY_REGISTERED: 'Already have an account?',
  VERIFY_ACCOUNT: ' Verify your identity',
  RESEND_CODE: 'You can resend code in',
  CODE_VARIFICATION: 'Enter the Email verification code and your new password below.',
  SECONDS: 'seconds',
  SIGNIN_HEADING: 'Login',
  SEND_EMAIL_TEXT:
    ' Enter the Email Address associated with your account and we will send an email with instructions to reset your password.'
};

export const generatePo = {
  DOCUMENT_CONTENT: 'Document Content',
  PURCHASE_ORDER: 'Purchase Order',
  DESCRIPTION: 'Description',
  TOTAL: 'Total',
  QUANTITY: 'Quantity',
  UNIT_PRICE: 'Unit Price',
  SHOW_LINEITEMS: 'Show LineItems'
};

export const headings = {
  TOKEN: 'token',
  ERROR_MESSAGE: 'Something went Wrong',
  ERP_SELECT: ' Select ERP Option',
  HEADING: 'Login',
  SIGNUP_HEADING: 'Sign up for free ',
  CREATE_ACCOUNT: 'Create Account',
  SIGNUP_QUESTION: "Don't have an account? ",
  SUBMIT: 'submit',
  PASSWORD: 'password',
  EMAIL: 'email',
  EMAIL_PLACEHOLDER: 'Email*',
  WORK_EMAIL_PLACEHOLDER: 'Work Email*',
  NAME_PLACEHOLDER: 'Name*',
  USER: 'userName',
  PASSWORD_PLACEHOLDER: 'Password*',
  GIVEN_NAME: 'given_name',
  VERIFICATION_CODE: 'Verification code',
  CONFIRM: 'Confirm',
  CHANGE_PASSWORD: 'Change Password',
  VENDOR: 'Vendor',
  CONTINUE: 'Continue',
  CANCEL: 'Cancel',
  AUTH_HEADING: 'Welcome to',
  BEIING_HUMAN: 'Beiing Human',
  ADD_USER: 'Add New User',
  ADD_VENDOR: 'Add New Vendor',
  REGISTER_USER: 'Register User',
  PAYMENT_DONE: 'Payment processed Successfully',
  THANK_YOU: 'Thank You!',
  PAYMENT_SUCCESSFULL: 'Your Payment Has Been Successful'
};

export const documentDetails = {
  BACK: 'Back',
  DOCUMENT_DETAILS: 'Document Details',
  ACCOUNT_DETAILS: 'Account Details'
};

export const canvasDetails = {
  DOCS: 'Review Document',
  APPROVE: 'Approve',
  CANCEL: 'Cancel',
  DOC_TYPE: 'Document Type',
  GL_ACCOUNT: 'GL Account',
  PHASE: 'Phase',
  JOB_ID: 'Job id',
  COST_ID: 'Cost id',
  COST_TYPE: 'Cost Type',
  VERIFY: 'Verify All',
  MATCH_PO: 'Match PO',
  MATCH_SUBCONTRACT: 'Match SC',
  VENDOR_NUMBER: 'Vendor Number',
  GL_YEAR: 'GL Year',
  GL_PERIOD: 'GL Period',
  RETENTION_AMOUNT: 'Retention Amount',
  FETCH_PO: 'Fetch PO',
  FETCH_PO_DESCRIPTION:
    'This Action will override your changes with the data from PO. Please confirm to proceed',
  FETCH_SC_DESCRIPTION:
    'This Action will override your changes with the data from Subcontract. Please confirm to proceed',
  PROJECT_ID: 'Project Id',
  CLASS: 'Class',
  RESET: 'Reset',
  RESET_DESCRIPTION: 'Reset Line Items from Invoice Image',
  INVALID_VENDOR_NAME:
    'This does not exist in ERP. Please update your information or sync the data.',
  CONTENT_VENDOR: 'vendor',
  FROM_CONTRACTOR: 'from_contractor',
  CONTENT_PURCHASEORDER: 'purchase_order',
  CONTENT_TOTAL: 'total',
  DESCRIPTION: 'Description',
  ZOOMING_ERROR: 'Zooming does not work on this screen',
  DT_LINEITEM: 'Delivery Ticket must have at least 1 line item',
  TOTAL_PROMPT_MESSAGE:
    'Document Total does not match with line items total. Are you sure you want to proceed?'
};

export const toasters = {
  VERIFICATION: 'User verified Successfully! Please Sign-in to continue',
  IS_CODE_SENT: 'Code has been sent to your email address',
  EMAIL_NOTE: 'Enter the verification code sent to your email address.',
  RESEND_CODE_LINK: ' Resend Code',
  EMAIL_ERROR: 'User is already Registered with given Email Address.',
  PASSWORD_CHANGED: 'Password Changed Successfully',
  TIME_ERROR: 'wait for the time to end',
  PO_MATCHED_ERROR: 'PO already exists with this Purchase Order'
};

export const accordion1 = {
  HEADING: 'How System works',
  SUB_HEADING: 'Send invoices via Email',
  STEP1:
    'Step 1 : Attach your invoices (up to 100?) and send the email to process@invoices.beinghumans.co',
  STEP2:
    'Step 2 (optional) :  You could see the status of invoices in your app.beinghumans.co account as they are getting verified by the operator',
  STEP3:
    'Step 3 : Receive the invoices back in your email: one csv file and a merged pdf? file of all the invoices together.  The time it takes to get back the csv of invoices data is 2 hours'
};

export const accordion2 = {
  HEADING: 'Upload Invoices in app',
  STEP1: 'Step 1 : Click on "Upload Files" Button on Home page.',
  STEP2: 'Step 2 : Drag "n" drop your file or directly click to attach file.',
  STEP3: 'Step 3 : Click on "Done" button to send your attachments.'
};
export const accordion3 = {
  HEADING: 'Quickbooks Online Integration',
  STEP1: 'Step 1 : On your home page, click on "QuickBooks Integration" in left bar.',
  STEP2: 'Step 2 : Click on "Connect to Quickbooks" button.',
  STEP3: 'Step 3 : Add your "INTUIT" account details to Sign-in.',
  STEP4:
    'Step 4 : Click on "Connect" button to allow connection with Being Humans Invoice Processor.'
};

export const userRoles = {
  ACCOUNTANT: 'Accountant',
  ADMIN: 'Admin',
  OWNER: 'Owner',
  CLEVEL: 'C_Level',
  MANAGER: 'Manager',
  VENDOR: 'Vendor'
};

export const QwcPopup = {
  PASSWORD_INFO: 'Enter Password to download file',
  NOTICE:
    'Please remember the password that you enter here. You need to provide the same password in your QuickBooks Web Connector',
  NOTE: 'Note:',
  DOWNLOAD_QWC_FILE: 'Download QuickBooks Web Connector File'
};
export const IntegrationPage = {
  INTEGRATION_MESSAGE: 'you have not selected any Integration',
  RECORD: 'No Record',
  QUICKBOOKS_ONLINE_SUBTITLE: 'Integrate your Beiing Human with QuickBooks.',
  QUICKBOOKS_DESKTOP_SUBTITLE: 'Integrate your Beiing Human with QuickBooks.',
  FOUNDATION_SUBTITLE: 'Integrate your Beiing Human with Foundation Software',
  SESSION_EXPIRE: 'Session expired ! Please again Login to continue',
  SOMETHING_WENT_WRONG: 'Something went wrong'
};
export const Stripe = {
  PAYMENT_CLEARED_MESSAGE: 'There is no pending payment available',
  HEADING: ' Enter Details to Pay'
};

export const REACT_APP_KEY = 'ahDP1BdbHP8B16auXvYBA0yzZEOlDm2W8m3FYgXdxZ/q5rDd2HEjxyjoUlor+xxr';

export const options = [
  'QuickBooks Desktop',
  'Foundation',
  'Accounting Seed',
  'Deltek Computer Ease',
  'Default'
];

export const signupOptions = [
  'QuickBooks Online',
  'QuickBooks Desktop',
  'Foundation',
  'Accounting Seed',
  'Deltek Computer Ease',
  'Other'
];

export const topNav = {
  DASHBOARD: 'Dashboard',
  BACK: 'Back',
  VIEW_DOC: 'View Supporting Document'
};
export const uploadFileArray = ['Vendor', 'Cost Ids', 'Job Ids', 'Gl Accounts', 'Purchase Order'];

export const TooltipMessages = {
  SPLIT_PDF_MESSAGE: 'Split multi-page PDFs into separate documents before processing',
  SEND_EMAIL: 'Get the processed data in your email.',
  DOWNLOAD_FILES: 'Download Selected Documents',
  DOWNLOAD_ALL_FILES: 'Download All Documents',
  SYNC_DATA: 'Fetch updated data from your integrated ERP',
  MANUAL_UPLOAD: 'Upload single csv file having account information. Such as PO, Vendors etc.',
  MANUAL_DOWNLOAD: 'Download files having account information. Such as PO, Vendors etc.',
  UPLOAD_FILE_MESSAGE: 'Upload documents for processing',
  SEARCH_MESSAGE:
    'You can Search by Vendor, Cost Id, Job Id, Cost Type, Total, Purchase Order, Document Number.',
  LINEITEMS_NOT_FOUND: 'Line item does not exist',
  AMOUNT_NOT_MATCHED: 'Amount do not match',
  DUPLICATE_LINEITEM: 'Duplicate Line item',
  SEE_DUPLICATE: 'Review Duplicate Document',
  SEARCH_ATTACHMENT_MESSAGE: 'You can Search by Attachment Id.'
};

export const accountDetailsComponent = {
  GL_ACCOUNT: 'GL Account',
  COST_ID: 'Cost Id',
  PHASE: 'Phase',
  JOB_ID: 'Job Id',
  COST_TYPE: 'Cost Type',
  ACTIVITY: 'Activity',
  SHOW_ACTIVITY: 'Show Activity',
  PURCHASE_ORDER: 'Purchase Order',
  VENDOR: 'Vendors',
  OVERRIDE_LIST: 'Do you want to override the previous list?',
  CLASS: 'Class',
  PROJECT_ID: 'Project Id',
  EQUIPMENT_NO: 'Equipment No',
  SERVICE_CODE: 'Service Code',
  APPROVED_BY: 'Approved By',
  SITTING_WITH: 'Currently Sitting With',
  PENDING_APPROVERS: 'Pending Approvers',
  NOT_APPLIED: 'Policy Not Applied',
  APPROVERS: 'Approvers'
};
export const manualUserComponent = {
  ADD_USER: 'Add Manual User',
  AMOUNT_CHECK: 'Amount must be greater than $0.50',
  UPLOAD: 'Upload file',
  MAKE_PAYMENT: 'User has been notified to make payment',
  SEND_BILL: 'Send bill to user',
  SEND_BILL_VENDOR: 'Send bill to vendor',
  AMOUNT: 'Amount $'
};
export const lineItemsText = {
  SHOW_LINEITEMS: 'Show Line Items'
};
export const documentStatusText = {
  POPULATED: 'Populated',
  APPROVED: 'Approved',
  PENDING: 'Pending',
  REJECT: 'Reject',
  WAITING: 'Waiting For Approval',
  ONHOLD: 'on Hold',
  ERROR: 'Error',
  DUPLICATE: 'Duplicate',
  EXPORT: 'Exported',
  ATTACHED: 'Attached'
};

export const tableComponent = {
  RECIEVED_AT: 'Received At',
  VENDOR_NAME: 'Vendor Name',
  AMOUNT_DUE: 'Amount Due',
  PO_NUMBER: 'PO Number',
  DUE_DATE: 'Due Date',
  DOCUMENT_NAME: 'Document Name',
  DOCUMENT_ID: 'Document ID',
  JOB_ID: 'Job ID',
  STATUS: 'Status',
  PENDING_DOC: 'Pending',
  ONHOLD_DOC: 'On Hold',
  REJECTED_DOC: 'Rejected',
  IN_REVIEW: 'In Review',
  PROCESSING: 'Processing',
  ERROR_DOC: 'Error',
  VERFIED: 'Verified',
  APPROVED_DOC: 'Approved',
  ACTIONS: 'Actions',
  VIEW: 'View',
  DELETE_DOCUMENT: 'Delete Document',
  DELETE_DOCUMENT_MESSAGE: 'Are you sure you want to delete this document',
  QB_INTEGRATION: 'QuickBooks integration is not available',
  SEARCH_NOT_FOUND: 'It appears that the information you’re attempting to find is not available.',
  DOCUMENT_NOT_FOUND: 'There are not any documents available. Upload documents to get started',
  APPROVER_DOCUMENT_NOT_FOUND: 'There are not any documents available.',
  GENERATE_PO: 'Generate PO',
  SEARCH_ATTACHMENTS: 'Search Attachments to View them',
  SEARCH_CHEQUES: 'No Record',
  INVOICE_NUMBER: 'Document No',
  NO_BATCHES_FOUND: 'There are not any email documents available',
  NO_OF_INVOICES: 'No of Invoices',
  FILE_NAME: 'File Name',
  ACTION: 'Action'
};

export const documentCommentsText = {
  COMMENTS: 'Comments',
  EMPTY_COMMENTS: `Looks like you haven't started a conversation yet`,
  IMAGE_NOT_AVAILABLE: 'Document Image is not Available',
  ERROR_DOWNLOADING_CSV: 'Error downloading the CSV file',
  DOCUMENT_NAME: 'Document Name',
  CREATED_AT: 'Created At',
  FAILED_REASON: 'Failed Reason'
};

export const policyPage = {
  MAX_VALUE_CHECK: 'Max value must be greater than min value or it should be infinity(0)',
  REQUIRED_APPROVALS: 'Required Approvals',
  AUTO: 'Auto',
  APPROVED: 'Approved',
  ADD_POLICY:
    'Initiate the Activation of the Approval Policy to implement a structured review process.',
  DELETE: 'Delete',
  CONFIRM_DELETE_POLICY: 'Are you sure you want to delete the policy?',
  POLICY_LEVEL: 'Policy Levels',
  POLICY_BASIS: 'Policy Basis',
  TOTAL: 'Total',
  VENDOR: 'Vendor',
  JOBID: 'Job Id/ Project Id',
  ADD_LEVELS: 'Add Levels',
  REMOVE_LEVEL: 'Remove Level',
  POLICY_NOT_ACTIVE: 'Approval Policy is not Active.',
  POLICY_AVAILABLE:
    'Policy creation is available once Manager / C Level registration has been completed..',
  FETCH_MANAGERS: 'Fetch Managers'
};

export const manualApprovalPage = {
  CUSTOM_APPROVAL: 'Custom Approval',
  REQUIRED_APPROVAL: 'Required Approvals',
  SELECT_APPROVER: 'Select User from whome you want the Approval',
  SUBMIT: 'Submit',
  NO_APPROVER: ' No Approver Required'
};

export const activity = {
  UPLOADED_BY: 'Uploaded By:',
  UPDATED_BY: 'Updated By:',
  STATUS: 'Status:',
  CONTENT: 'Content',
  LINEITEM: 'Lineitem',
  ATTACHMENT: 'Attachments',
  DELIVERY_TICKET: 'Supporting Documents',
  CREATED_BY: 'Created By'
};

export const dashboardText = {
  AVERAGE_TIME: 'Average approval time for each document',
  TIME: 'AVG Time:',
  MINUTES: 'Minutes',
  AVERAGE_CHANGES: 'Average changes per document',
  CHANGES: 'AVG Changes:',
  CHANGE: 'Changes',
  VENDOR_DOCUMENTS: 'Documents by  Vendor',
  AMOUNT_DOCUMENTS: 'Documents by  Amount ($)',
  AGING_DOCUMENTS: 'Documents by Aging',
  AGING_TOTAL: 'Total by Aging ($)',
  JOBID_DOCUMENTS: 'Documents by Job ID',
  COSTID_DOCUMENTS: 'Documents by Cost ID',
  RECORD_NOT_FOUND: 'No records were found associated with this filter.',
  DOCUMENT_STATUS_RATIO: 'Documents by Error Ratio and Success Ratio',
  DOC_TYPE: 'Documents by Type',
  RESPONSE_TIME: 'Response Time by Each Document',
  RECORD_NOT_FOUND: 'No records were found associated with this filter.'
};

export const duplicateDoc = {
  DUPLICATE_DOC: 'Duplicate Document',
  CANCEL: 'Cancel',
  CONTINUE: 'Proceed Anyway',
  PREVIOUS: 'Previous',
  NEW: 'New',
  DELETE: 'Delete'
};

export const unauthorizedPage = {
  UNAUTHORIZED: 'Unauthorized Access',
  UNAUTHORIZED_INFORMATION:
    'Oops! You do not have permission to view this page. It looks like you might not be authorized to access this content. If you believe this is an error, please check with your administrator or the person in charge.',
  SOMETHING_WRONG: 'Oops! Something Went Wrong',
  REFRESH_PAGE:
    'Either refresh the page or return home to try again and if the issue still exists ,  please check with Beiing Human.'
};

export const documentTypes = {
  ESTIMATE: 'Estimate',
  DELIVERY_TICKET: 'DeliveryTicket',
  INVOICE: 'Invoice',
  G702: 'G702',
  RECEIPT: 'Receipt'
};

export const attachmentPage = {
  CONNECT_ERP: 'Connect your erp to get vendors list',
  NO_DOCUMENTS: 'No Documents',
  SELECTED_DOCUMENTS: 'Queue',
  ERROR: 'Documents not selected',
  SEARCH_DOCUMENTS: 'Search Document',
  LOADING: 'Loading...',
  NO_ATTACHMENTS: 'No Attachments',
  ATTACHMENTS: 'Upload Attachments'
};

export const ConstructionCompanySyncArray = [
  { name: 'Vendor', value: ['vendors'] },
  { name: 'Accounts', value: ['accounts'] },
  { name: 'Job Ids', value: ['jobIds'] },
  { name: 'Cost Ids', value: ['costIds'] },
  { name: 'Purchase Order', value: ['purchaseOrder'] },
  { name: 'Cost Type', value: ['costType'] },
  { name: 'Subcontract', value: ['subContract'] },
  {
    name: 'All',
    value: ['vendors', 'accounts', 'jobIds', 'costIds', 'costType', 'purchaseOrder', 'subContract']
  }
];
export const UserSyncArray = [
  { name: 'Vendor', value: ['vendors'] },
  { name: 'Accounts', value: ['accounts'] },
  { name: 'Purchase Order', value: ['purchaseOrder'] },
  { name: 'Subcontract', value: ['subContract'] },
  { name: 'All', value: ['vendors', 'accounts', 'purchaseOrder', 'subContract'] }
];

export const QueuePopup = {
  USER_QUEUE: 'User Queue',
  NO_RECORD: 'No Record',
  PROGRESS: 'In Progress',
  PENDING: 'Pending',
  NO_PENDING_TASK: 'No pending tasks',
  CLEAR_QUEUE: 'Clear Queue',
  QUEUE_MESSAGE: 'Are you sure you want to clear the queue?'
};

export const LearnMoreLink = 'https://beiinghuman.com/quickbooks-integration/';
export const DisconnectLink = 'https://beiinghuman.com/quickbooks-disconnected/';

export const supportingDocumentsPopup = {
  NO_DOCUMENTS: 'No Document found',
  INVOICE_LINEITEMS: 'Invoice LineItems',
  NO_LINEITEMS: 'No Line Items',
  DESCRIPTION: 'Description',
  QUANTITY: 'Quantity'
};

export const attachDeliveryTicketPage = {
  DELIVERY_TICKET: 'Delivery Tickets',
  ATTACH_DELIVERY_TICKET: 'Attached Delivery Tickets',
  NO_DT: 'No Delivery Tickets Attached',
  NO_DT_FOUND: 'No Delivery Tickets Found',
  NO_SUGGESTED_DT: 'No Suggetsed Delivery Tickets',
  DT_SUGGESTION_CRITERIA:
    'Invoice No cannot be empty, Vendor must match and delivery ticket date must be same or earlier than document date.',
  SUGGESTED_DELIVERY_TICKET: 'Suggested Delivery Tickets',
  SHOW_ALL_DT: 'Show All Delivery Tickets',
  ALL_DELIVERY_TICKETS: ' All Delivery Tickets'
};
