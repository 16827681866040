import React, { useState, useRef } from 'react';
import { Container, Grid, Box as MuiBox } from '@mui/material';

function SupportingDocumentWithBoundingBoxes({
  lineItemsArray,
  dTUrl,
  dtIndex,
  imageDimensions,
  setImageDimensions,
  selectedItemIndex
}) {
  const [isZoomed, setIsZoomed] = useState(false);
  const imgElement = useRef(null);

  const handleImageLoad = (imageRef) => {
    if (imageRef) {
      const dimensions = {
        width: imageRef.width,
        height: imageRef.height
      };
      setImageDimensions(dimensions);
    }
  };
  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  const filteredItemsArray = lineItemsArray?.map((row) =>
    row?.filter(
      (item) =>
        (item?.name === 'Description' || item?.name === 'Quantity') &&
        (item.page === dtIndex || item?.page === '-' || item?.page === '')
    )
  );

  return (
    <Container
      style={{
        padding: 0,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: { xs: 'max-content', lg: 'auto' }
      }}>
      {isZoomed && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            zIndex: 1400
          }}
          onClick={toggleZoom}
        />
      )}

      <Grid
        container
        style={{
          position: 'relative'
        }}>
        <img
          ref={imgElement}
          src={dTUrl[dtIndex]}
          alt={dtIndex}
          onClick={toggleZoom}
          style={{
            cursor: 'pointer',
            height: 'inherit',
            width: 'inherit',
            objectFit: 'contain',
            zIndex: 1300,
            aspectRatio: 'auto'
          }}
          onLoad={() => handleImageLoad(imgElement.current)}
        />
        {isZoomed && (
          <img
            ref={imgElement}
            className={'zoomed'}
            src={dTUrl[dtIndex]}
            alt={dtIndex}
            onClick={toggleZoom}
            style={{
              cursor: 'pointer',
              height: '100%',
              width: 'auto',
              objectFit: 'contain',
              position: 'fixed',
              top: '0',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 1500,
              maxHeight: '100vh',
              maxWidth: '100vw',
              aspectRatio: 'auto'
            }}
          />
        )}
        {!isZoomed &&
          filteredItemsArray?.map((element, parentIndex) =>
            element?.map((items, idx) => (
              <MuiBox
                key={idx}
                sx={{
                  height: items.height * imageDimensions.height,
                  backgroundColor:
                    selectedItemIndex === 'button_' + items.value + '**%**' + parentIndex + idx
                      ? '#00FF3C'
                      : 'transparent',
                  width: items.width * imageDimensions.width,
                  position: 'absolute',
                  top: items.top * imageDimensions.height,
                  left: items.left * imageDimensions.width,
                  opacity:
                    selectedItemIndex === 'button_' + items.value + '**%**' + parentIndex + idx
                      ? 0.4
                      : 0,
                  cursor: 'pointer',
                  zIndex: 1301
                }}
              />
            ))
          )}
      </Grid>
    </Container>
  );
}

export default SupportingDocumentWithBoundingBoxes;
