import React from 'react';

import { Typography, Card } from '@mui/material';

import { Colors } from '../config/default';
import CustomDrawer from './customDrawer';

export default function MainCards({ title, data, type }) {
  const formatData = data || '0';
  const fontSize = formatData.toString().length > 9 ? '1.5rem' : '2rem';
  return (
    <Card
      sx={{
        border: `2px solid ${Colors.TOURQUISE}`,
        borderRadius: '20px',
        width: { xs: '100%', sm: '20%', lg: '10.35vw' },
        height: '4.5rem',
        padding: '1rem'
      }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '1rem', color: Colors.TOURQUISE }}>{title}</Typography>
        <CustomDrawer heading={title} type={type} count={formatData} />
      </div>
      <Typography sx={{ fontSize: fontSize }}>{formatData}</Typography>
    </Card>
  );
}
