import React, { useState } from 'react';
import { isEmpty } from 'lodash';

import { Grid, Select, MenuItem, Typography, Button } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { BootstrapInput } from '../services/common';
import { Colors } from '../config/default';
import DropdownEmails from './dropDownEmails';
import theme from '../theme';
import { manualApprovalPage } from '../constants/appConstants';

function ManualApprovalSelections({ apiData, mails, setMails, submitData }) {
  const { CUSTOM_APPROVAL, REQUIRED_APPROVAL, SELECT_APPROVER, NO_APPROVER } = manualApprovalPage;
  const [requiredApprovals, setRequiredApprovals] = useState(1);
  const approvalArray = [0, 1, 2, 3];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        borderRadius: '10px',
        backgroundColor: Colors.NAV_BLACK
      }
    }
  };

  const handleRequiredApprovalsChange = (e) => {
    const newRequiredApprovals = parseInt(e.target.value, 10);
    const currentApprovals = mails || [];
    const selectedApprovals = currentApprovals.slice(0, newRequiredApprovals);
    setRequiredApprovals(newRequiredApprovals);
    setMails(selectedApprovals);
  };
  const removeselectedEmail = (i) => {
    let newUser = [];
    mails?.forEach((mail) => {
      mail?.forEach((user) => {
        newUser = [...newUser, user];
      });
    });

    const filteredEmails =
      apiData &&
      apiData?.filter((childItem) => {
        return mails[i]?.includes(childItem) || !newUser.includes(childItem);
      });

    return filteredEmails?.map((item) => item);
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid item sx={{ p: 1, height: '400px' }}>
        <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography
            sx={{ color: Colors.TOURQUISE, fontSize: '1.1rem', fontWeight: '600', mb: '1rem' }}>
            {CUSTOM_APPROVAL}
          </Typography>
        </Grid>
        <Typography sx={{ color: Colors.TEXTGREY, fontSize: '.8rem', ml: '.2em', mb: '.5em' }}>
          {REQUIRED_APPROVAL}
        </Typography>
        <Select
          fullWidth
          variant="outlined"
          sx={{
            borderRadius: '0.6em',
            outline: 'none',
            color: Colors.TEXTGREY,
            height: '3rem',
            padding: '1em 0em',
            textAlign: 'left',
            border: '.7px solid #BABABA'
          }}
          MenuProps={MenuProps}
          input={<BootstrapInput />}
          value={requiredApprovals}
          onChange={(e) => handleRequiredApprovalsChange(e)}>
          {approvalArray?.map((num) => {
            return (
              <MenuItem
                sx={{
                  color: Colors.TEXTGREY,
                  borderRadius: '8px',
                  margin: '0px 8px',
                  '&:hover': {
                    borderRadius: '7px',
                    backgroundColor: Colors.BG_BLACK
                  }
                }}
                value={num}>
                {num}
              </MenuItem>
            );
          })}
        </Select>
        {requiredApprovals === 0 && (
          <Typography
            sx={{ color: Colors.TEXTGREY, fontSize: '.7rem', ml: '.5em', mb: '.5em', mt: '.3em' }}>
            {NO_APPROVER}
          </Typography>
        )}
        <Grid
          item
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: '1rem',
            height: requiredApprovals === 0 ? '170px' : '190px'
          }}>
          {requiredApprovals !== 0 && (
            <Typography sx={{ color: Colors.TEXTGREY, fontSize: '.8rem', ml: '.2em', mb: '.5em' }}>
              {SELECT_APPROVER}
            </Typography>
          )}

          {Array?.from({ length: requiredApprovals }, (_, i) => (
            <span style={{ marginBottom: '.5rem' }}>
              <DropdownEmails
                key={i}
                index={i}
                setMails={(email) => setMails(email)}
                mails={mails}
                apiData={removeselectedEmail(i)}
                fullWidth={true}
                toShow="canvas"
              />
            </span>
          ))}
        </Grid>
        <Grid item sx={{ display: 'flex', justifyContent: 'center', mt: '2rem' }}>
          <Button
            disabled={
              mails?.filter((subArray) => subArray && subArray?.[0])?.length !== requiredApprovals
            }
            variant="contained"
            sx={{
              borderRadius: '10px',
              textTransform: 'none',
              color: Colors.WHITE,
              '&:disabled': {
                color: 'gray'
              }
            }}
            onClick={submitData}>
            {requiredApprovals === 0 ? 'Approve' : 'Submit for Approval'}
          </Button>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default ManualApprovalSelections;
