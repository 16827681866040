import React from 'react';
import { useSelector } from 'react-redux';

import { Grid } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import TopNav from './topNav';
import theme from '../theme';

function Layout({
  isDrawerOpen,
  activeTab,
  setActiveTab,
  currentTabs,
  path,
  filteredData,
  recordExist,
  handleClear,
  show,
  buttonArray,
  submitData,
  id,
  comment,
  setComment,
  docName,
  padding,
  approvers,
  setImages,
  annotationType,
  handleClearAttachmentSearch,
  isAttachmentSearched,
  deliveryTicketUpload,
  setRefId,
  handleClearErpSearch,
  refId,
  vendor,
  po,
  invoiceNumber,
  date,
  images,
  lineItem,
  lineItemsData,
  setIndex
}) {
  const user = useSelector((state) => state.signIn?.signIn?.cognitoRes?.idToken?.payload);

  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <TopNav
          isDrawerOpen={isDrawerOpen}
          padding={padding}
          username={user?.name}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          currentTabs={currentTabs}
          path={path}
          userRole={user?.given_name}
          filteredData={filteredData}
          recordExist={recordExist}
          handleClear={handleClear}
          show={show}
          buttonArray={buttonArray}
          submitData={submitData}
          id={id}
          comment={comment}
          setComment={setComment}
          docName={docName}
          approvers={approvers}
          setImages={setImages}
          annotationType={annotationType}
          isAttachmentSearched={isAttachmentSearched}
          handleClearAttachmentSearch={handleClearAttachmentSearch}
          deliveryTicketUpload={deliveryTicketUpload}
          setRefId={setRefId}
          handleClearErpSearch={handleClearErpSearch}
          refId={refId}
          vendor={vendor}
          po={po}
          invoiceNumber={invoiceNumber}
          date={date}
          images={images}
          lineItem={lineItem}
          lineItemsData={lineItemsData}
          setIndex={setIndex}
        />
      </Grid>
    </ThemeProvider>
  );
}

export default Layout;
