import React from 'react';

import { DataGrid } from '@mui/x-data-grid';
import { Grid, Box } from '@mui/material';
import { ErrorOutlineRounded } from '@mui/icons-material';

import { Colors } from '../config/default';
import { tableComponent } from '../constants/appConstants';

const datagridSx = {
  border: 'none',
  color: Colors.TEXTGREY,
  paddingX: '1rem',
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important'
  },
  '& .MuiDataGrid-main': { borderRadius: '10px' },
  '& .MuiDataGrid-virtualScrollerRenderZone': {
    borderBottom: 'none !important',
    '& .MuiDataGrid-root': { border: 'none !important' },
    '& .MuiDataGrid-row': {
      width: '99%',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: '#24658D !important',
        color: Colors.WHITE
      },
      '&:nth-of-type(2n-1)': {
        backgroundColor: '#1E1E1E'
      }
    },
    '& div': { border: 'none' }
  },

  '& .MuiDataGrid-columnCheckbox': {
    backgroundColor: '#fdfdfd',
    width: '20px',
    height: '20px'
  },

  '& .MuiDataGrid-columnHeaders': {
    border: 'none !important',
    color: Colors.WHITE
  }
};

export default function AdminDataTable({ rows, columns, overlay }) {
  const { NO_BATCHES_FOUND, SEARCH_CHEQUES } = tableComponent;
  function CustomNoRowsOverlay() {
    return (
      <Grid
        container
        sx={{
          height: 'inherit',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}>
        <Box
          sx={{
            backgroundColor: Colors.NAV_BLACK,
            padding: '.5em',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: { xs: '.7em', md: '1em' },
            marginTop: '.5em'
          }}>
          <ErrorOutlineRounded
            sx={{ color: Colors.TEXTGREY, marginRight: '.3em', fontSize: '1.3em' }}
          />
          {overlay ? NO_BATCHES_FOUND : SEARCH_CHEQUES}
        </Box>
      </Grid>
    );
  }
  return (
    <div style={{ width: '100%', height: '75vh' }}>
      <DataGrid
        sx={datagridSx}
        rows={rows}
        columns={columns}
        autoPageSize
        checkboxSelection={false}
        disableRowSelectionOnClick
        slots={{
          noRowsOverlay: CustomNoRowsOverlay
        }}
      />
    </div>
  );
}
