import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import { Close, ErrorOutline } from '@mui/icons-material';
import { Grid, CircularProgress, Typography } from '@mui/material';

import { getSyncedData } from '../services/services';
import { QueuePopup } from '../constants/appConstants';
import { Colors } from '../config/default';

function SyncedDataList({ heading, type, toggleDrawer, anchor }) {
  const { NO_RECORD } = QueuePopup;
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const getData = async () => {
    setLoading(true);
    const result = await getSyncedData(type);
    if (result?.status === 200) {
      setDataList(result?.data?.payload?.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (type) {
      getData();
    }
  }, [type]);

  return (
    <Grid
      sx={{
        alignItems: 'center',
        height: '100vh',
        backgroundColor: Colors.NAV_BLACK
      }}>
      <>
        <Grid>
          <Grid
            container
            sx={{ padding: '0em 2em', mt: '1.5em', mb: '1em', justifyContent: 'flex-end' }}>
            <Close
              onClick={toggleDrawer(anchor, false)}
              sx={{ cursor: 'pointer', fontSize: '1.5em', color: Colors.LIGHTGREY }}
            />
          </Grid>
          <Grid
            container
            sx={{
              height: '83vh',
              width: { xs: '20em', sm: '28em', xl: '42em' },
              padding: '2em 0.8em',
              borderRadius: '1em',
              backgroundColor: Colors.BG_BLACK,
              flexDirection: 'column',
              justifyContent: 'flex-start',
              m: '0em 2em'
            }}>
            <Grid item>
              <Typography sx={{ color: Colors.TOURQUISE, fontSize: '1em', ml: '1.1em' }}>
                {heading}
              </Typography>
            </Grid>
            {loading ? (
              <Grid
                container
                sx={{ alignItems: 'center', justifyContent: 'center', height: '60vh' }}>
                <CircularProgress size={22} sx={{ color: Colors.DARKBLUE }} />
              </Grid>
            ) : (
              <Grid
                item
                sx={{
                  marginTop: '.5rem',
                  height: '65vh',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: '.2em',
                    height: '0em',
                    borderRadius: '20px'
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
                    outline: '1px solid slategrey'
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#fff',
                    outline: '1px solid slategrey'
                  }
                }}>
                {isEmpty(dataList) ? (
                  <Grid
                    container
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      color: Colors.TEXTGREY
                    }}>
                    <ErrorOutline
                      sx={{ color: Colors.TEXTGREY, marginRight: '.3em', fontSize: '1.1em' }}
                    />
                    <Typography>{NO_RECORD}</Typography>
                  </Grid>
                ) : (
                  dataList?.map((item, index) => (
                    <div
                      key={index}
                      style={{ display: 'flex', alignItems: 'center', marginTop: '.5rem' }}>
                      <span style={{ width: '40px', textAlign: 'right', color: Colors.TOURQUISE }}>
                        {`${index + 1}.`}
                      </span>
                      <span style={{ marginLeft: '10px' }}>{item}</span>
                    </div>
                  ))
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </>
    </Grid>
  );
}

export default SyncedDataList;
