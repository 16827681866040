import React, { useRef } from 'react';
export const findMissingUtil = (first, second) => {
  const spreaded = [...first, ...second];
  return spreaded.filter((el) => {
    return !(first.includes(el) && second.includes(el));
  });
};
export const useDynamicRefs = () => {
  const refs = useRef({});
  const setRef = (key) => {
    if (!refs.current[key]) {
      refs.current[key] = React.createRef();
    }
    return refs.current[key];
  };
  const getRef = (key) => {
    return refs.current[key];
  };
  return [setRef, getRef];
};
