import React from 'react';

import { Card, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ErrorIcon from '../assets/error.png';
import { Colors } from '../config/default';
import { unauthorizedPage } from '../constants/appConstants';
import Btn from '../components/button';

export default function FallBack() {
  const { SOMETHING_WRONG } = unauthorizedPage;
  const navigate = useNavigate();
  const handleHomeNavigation = () => {
    navigate('/');
    location.reload();
  };

  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.BG_BLACK
      }}>
      <Card
        sx={{
          width: { xs: '70vw', md: '50vw' },
          backgroundColor: Colors.NAV_BLACK,
          borderRadius: '16px',
          textAlign: 'center',
          padding: '2em'
        }}>
        <img style={{ marginTop: '2em', height: '10em' }} src={ErrorIcon} alt="" />
        <Typography sx={{ color: Colors.WHITE, fontSize: '2rem', mt: '1em', mb: '1em' }}>
          {SOMETHING_WRONG}
        </Typography>
        <Btn
          onClick={handleHomeNavigation}
          text="Go to Home"
          variant="contained"
          width="max-content"
          radius="10px"
          color={Colors.WHITE}
        />
      </Card>
    </div>
  );
}
