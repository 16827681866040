import React, { useState } from 'react';

import { Drawer, IconButton, Typography } from '@mui/material';

import { Colors } from '../config/default';
import { HtmlTooltip } from '../services/common';

import { RemoveRedEye } from '@mui/icons-material';
import SyncedDataList from './SyncedDataList';

export default function CustomDrawer({ heading, type, count }) {
  const [state, setState] = useState({
    right: false
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <SyncedDataList heading={heading} type={type} toggleDrawer={toggleDrawer} anchor={anchor} />
  );
  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <HtmlTooltip
            title={
              <Typography color="inherit" sx={{ fontSize: '1em' }}>
                View List
              </Typography>
            }>
            <span>
              <IconButton
                disabled={count === 0 || count === '0'}
                onClick={(event) => {
                  event.stopPropagation();
                  toggleDrawer(anchor, true)(event);
                }}
                aria-label="activity">
                <RemoveRedEye
                  sx={{
                    fontSize: '1.2rem',
                    color: count === 0 || count === '0' ? Colors.DISABLEDGRAY : Colors.TOURQUISE
                  }}
                />
              </IconButton>
            </span>
          </HtmlTooltip>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
