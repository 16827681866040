import React, { useState, useEffect } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme';

import { getBatchById } from '../services/services';
import SplitDoc from './splitDoc';

function BactchDocuments({ setOpen, data, getbatches }) {
  const [splitDocData, setSplitDocDaata] = useState([]);
  const [openSplit, setOpenSplit] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const getbatchDocument = async () => {
    setOpenSplit(true);
    const result = await getBatchById(data?.id);
    if (result?.status === 200) {
      setSplitDocDaata(result?.data?.payload?.data);
    }
    setOpenSplit(false);
  };
  useEffect(() => {
    getbatchDocument();
  }, [data?.id]);
  return (
    <ThemeProvider theme={theme}>
      <SplitDoc
        handleClose={handleClose}
        splitDocData={splitDocData}
        batchId={data?.id}
        getbatches={getbatches}
        openSplit={openSplit}
      />
    </ThemeProvider>
  );
}

export default BactchDocuments;
