import React from 'react';

import { Grid, Typography } from '@mui/material';

import { Colors } from '../config/default';
import { formatDate } from '../services/common';
import { documentCommentsText } from '../constants/appConstants';

export default function ErrorDetailsList({ data }) {
  const { DOCUMENT_NAME, CREATED_AT, FAILED_REASON } = documentCommentsText;
  return (
    <div>
      <Grid
        container
        xs={12}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          color: Colors.TEXTGREY,
          mb: '1.5em'
        }}>
        <Grid xs={4}>
          <Typography sx={{ fontSize: '0.80em' }}>{DOCUMENT_NAME}</Typography>
        </Grid>
        <Grid xs={7} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Typography sx={{ fontSize: '0.80em' }}>
            {data?.key || data?.document?.originalFileName || '-'}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          color: Colors.TEXTGREY,
          mb: '1.5em'
        }}>
        <Grid xs={4}>
          <Typography sx={{ fontSize: '0.80em' }}>{CREATED_AT}</Typography>
        </Grid>
        <Grid xs={7} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Typography sx={{ fontSize: '0.80em' }}>
            {formatDate(data?.createdAt || data?.document?.createdAt)}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          color: Colors.TEXTGREY,
          mb: '1.5em'
        }}>
        <Grid xs={4}>
          <Typography sx={{ fontSize: '0.80em' }}>{FAILED_REASON}</Typography>
        </Grid>
        <Grid xs={7} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Typography sx={{ fontSize: '0.80em' }}>
            {data?.failedReason || data?.document?.message || '-'}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
