import React, { useState } from 'react';
import { useToast } from '../toast/toastContext';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Grid, Button, CircularProgress, Typography } from '@mui/material';

import { updateVendor, getVendors } from '../services/services';
import { Colors } from '../config/default';
import CustomTextField from './textfield';
import { get_all_vendors } from '../redux/actions/action';

export default function EditVendor({ setOpen, data }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const [limit, setLimit] = useState(data?.docLimit || '');
  const [loading, setLoading] = useState(false);
  const UpdateVendor = async (e) => {
    e.preventDefault();
    setLoading(true);
    const params = {
      limit: limit,
      clientId: data?.clientId
    };
    const result = await updateVendor(params);
    if (result?.status === 200) {
      showToast(result?.data?.metadata?.message, 'success');
      const res = await getVendors();
      if (res?.response?.status === 401) {
        localStorage.clear();
        navigate('/');
      }
      dispatch(get_all_vendors(res?.data?.payload?.data));
      setOpen(false);
    } else {
      showToast(result?.data?.metadata?.message || result?.res?.data?.metadata?.message, 'error');
    }
    setLoading(false);
  };
  const handleKeyPress = (e) => {
    if (e.key === '.' || (e.key === 'e' && e.target.value.includes('.'))) {
      e.preventDefault();
      return;
    }
    if (e.key === 'Enter' && limit.trim() !== '' && limit >= 0) {
      UpdateVendor(e);
    }
  };
  return (
    <Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography
          sx={{
            color: Colors.WHITE,
            fontWeight: 'bold',
            marginBottom: '2rem',
            fontSize: '1.2em'
          }}>
          Edit Documents Limit
        </Typography>
      </Grid>
      <CustomTextField
        setValue={setLimit}
        value={limit}
        placeHolder="Documents Limit"
        toShow="vendor"
        type="number"
        handleKeyPress={handleKeyPress}
      />
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="outlined"
          disabled={limit === '-' || limit === '' || loading || limit <= 0}
          onClick={(e) => UpdateVendor(e)}
          sx={{
            height: '2.2rem',
            marginTop: '2em',
            width: '6rem',
            borderRadius: '10px',
            color: Colors.WHITE,
            textTransform: 'none'
          }}>
          {loading ? <CircularProgress size={20} color="inherit" /> : 'Save'}
        </Button>
      </Grid>
    </Grid>
  );
}
