import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import CheckoutForm from './stripeForm';
import { getPaymentIntent, getManualUserPaymentIntent } from '../services/services';
import { Stripe } from '../constants/appConstants';
import { StripeKey } from '../constants/appConstants';
import { useToast } from '../toast/toastContext';

export default function CheckoutFormPage() {
  const queryParams = new URLSearchParams(location.search);
  const manualCustomerEmail = queryParams.get('manualCustomerEmail');
  const [isLoading, setIsLoading] = useState(true);
  const STRIPE_KEY = StripeKey();
  const { PAYMENT_CLEARED_MESSAGE } = Stripe;
  const navigate = useNavigate();
  const customerEmail = localStorage.getItem('stripeEmail');
  const [clientID, setClientID] = useState();
  const [price, setPrice] = useState();
  const { showToast } = useToast();
  const callSource = localStorage.getItem('callSource');

  const createPayment = async () => {
    let result;
    if (manualCustomerEmail) {
      const manualUser = { email: manualCustomerEmail };
      result = await getManualUserPaymentIntent(manualUser);
    } else {
      const params = { customerEmail: customerEmail, callSource: callSource };
      result = await getPaymentIntent(params);
    }
    if (result?.status === 200) {
      setClientID(result?.data?.payload?.data?.client_secret);
      setPrice(result?.data?.payload?.data?.amount);
    } else if (result?.response?.status === 401) {
      localStorage.clear();
      navigate('/');
    } else if (result?.response?.status === 400) {
      showToast(PAYMENT_CLEARED_MESSAGE, 'error');
      navigate('/');
    } else {
      showToast(result?.response?.data?.metadata?.message, 'error');
    }
    setIsLoading(false);
  };
  useEffect(() => {
    createPayment();
  }, [customerEmail]);

  const stripePromise = loadStripe(STRIPE_KEY);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  const options = {
    clientSecret: clientID,
    appearance: { theme: 'night' }
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm customerEmail={customerEmail} price={price} />
    </Elements>
  );
}
