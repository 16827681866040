import React, { useEffect, useState } from 'react';

import GeneratePoCard from './generatePoCard';
import { CircularProgress, Grid, Typography, Modal, Hidden, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { DescriptionOutlined, ShoppingCart, Check } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import { getSupportingDocument } from '../services/services';
import { Colors } from '../config/default';
import Navigation from './navigation';
import { autoCap } from '../services/validationService';
import PaginationButtons from './paginationButtons';
import { formatNumber } from '../services/common';
import SupportingDocumentWithBoundingBoxes from './SupportingDocumentBoundingBoxes';
import { supportingDocumentsPopup } from '../constants/appConstants';
import { formatLineItems } from '../services/common';

function SupportingDocument({
  refId,
  po,
  annotationType,
  deliveryTicketUpload,
  poMatchPermission,
  lineItem,
  lineItemsData
}) {
  const { NO_DOCUMENTS, INVOICE_LINEITEMS, NO_LINEITEMS, DESCRIPTION, QUANTITY } =
    supportingDocumentsPopup;
  const smallScreen = useMediaQuery('(max-height:1400px)');
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const companyId = userDetails?.company?._id;
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(false);
  const [images, setImages] = useState([]);
  const { id } = useParams();
  const [selectedItemIndex, setSelectedItemIndex] = useState(null); // New state to track selected item
  const handleImageClick = () => {
    setSelectedImage(!selectedImage);
  };
  const findValue =
    annotationType === 'G702' ||
    ((annotationType === 'Invoice' || annotationType === 'Receipt') &&
      deliveryTicketUpload &&
      !isEmpty(refId))
      ? 1
      : annotationType === 'Invoice' || annotationType === 'Receipt'
      ? deliveryTicketUpload && poMatchPermission
        ? 0
        : poMatchPermission
        ? 0
        : 1
      : 0;
  const [navigationValue, setNavigationValue] = useState(findValue);
  const [index, setIndex] = useState(0);
  const [dtIndex, setDtIndex] = useState(0);
  const [documentContent, setDocumentContent] = useState([]);
  const [documentLineItems, setDocumentLineItems] = useState([]);
  const [response, setResponse] = useState({});
  const [cardOpen, setCardOpen] = useState(false);
  const [dTUrl, setDTUrl] = useState([]);
  const [imageDimensions, setImageDimensions] = useState({});
  const [supportindDocLineitems, setSupportingDocLineItems] = useState([]);
  useEffect(() => {
    setDtIndex(0);
    setIndex(0);
  }, [navigationValue]);
  const getDocument = async () => {
    setLoading(true);
    let lineItemsCopy = [...lineItem];
    Object.keys(lineItemsData)?.forEach((key) => {
      if (key !== undefined && lineItemsData[key] !== undefined) {
        lineItemsData[key]?.forEach((val, index) => {
          if (val !== undefined && lineItemsCopy[index] !== undefined) {
            const check = lineItemsCopy[index]?.findIndex((item) => item?.name === key);
            if (check !== -1) {
              lineItemsCopy[index][check].value = val === 'N/A' ? '' : val;
            }
          }
        });
      }
    });
    const lineItems = formatLineItems(lineItemsCopy);
    const type = navigationValue === 1 ? 'DeliveryTicket' : 'Po';
    const findRef = !isEmpty(refId) ? refId[0]?.id : 'N/A';
    const recordNumber = navigationValue === 1 ? findRef : po;
    const initalizedValue = recordNumber === '' ? 'N/A' : recordNumber;
    const params = {
      documentType: type,
      companyId: companyId,
      recordNumber: initalizedValue,
      lineItems: lineItems
    };
    const result = await getSupportingDocument(id, params);

    if (result?.status === 200) {
      setResponse(result?.data?.payload?.data);
      setDocumentContent(result?.data?.payload?.data?.supportingDocument?.standardContent);
      setDocumentLineItems(result?.data?.payload?.data?.supportingDocument?.standardizeLineItems);
      setImages(result?.data?.payload?.data?.document?.convertedS3Keys);
      setDTUrl(result?.data?.payload?.data?.supportingDocument?.allSignedUrl);
      setSupportingDocLineItems(result?.data?.payload?.data?.supportingDocument?.lineItems);
    } else {
      setResponse({});
    }

    setLoading(false);
  };
  useEffect(() => {
    getDocument();
  }, [navigationValue]);
  const slideRight = () => {
    setIndex((index + 1) % images.length);
  };
  const slideLeft = () => {
    const nextIndex = index - 1;
    if (nextIndex < 0) {
      setIndex(images.length - 1);
    } else {
      setIndex(nextIndex);
    }
  };
  const documentContentMapped = documentContent
    ?.map((item) => {
      if (item?.name === 'Estimate_no') {
        return {
          name: 'Order no',
          value: item?.value
        };
      } else if (item?.name === 'Estimate_date') {
        return {
          name: 'Date',
          value: item?.value
        };
      } else if (item?.name === 'Expiration_date') {
        return null;
      } else {
        return {
          name: autoCap(item?.name),
          value: item?.value
        };
      }
    })
    .filter((item) => item !== null);

  const documentLineItemsMapped = documentLineItems?.map((item) => ({
    ...item,
    qty: formatNumber(parseFloat(item?.qty)),
    total: formatNumber(parseFloat(item?.total)),
    unit_price: formatNumber(parseFloat(item?.unit_price))
  }));

  const lineItemsPerPage = 12;
  const remainingLineitems = documentLineItemsMapped?.length - lineItemsPerPage;
  const totalCardPages = Math?.ceil(remainingLineitems / lineItemsPerPage);
  const filteredArray = documentContentMapped?.filter((item) => item?.value !== '');
  const handleCardOpen = () => {
    setCardOpen(true);
  };
  const handleClose = () => {
    setCardOpen(false);
  };
  const slideRightDT = () => {
    setDtIndex((dtIndex + 1) % dTUrl.length);
  };
  const slideLeftDT = () => {
    const nextIndex = dtIndex - 1;
    if (nextIndex < 0) {
      setDtIndex(dTUrl?.length - 1);
    } else {
      setDtIndex(nextIndex);
    }
  };
  const divStyle = { margin: '0 10px', color: Colors.WHITE, display: 'inline-flex' };
  const scrollStyle = {
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      width: '0em',
      height: '0em',
      borderRadius: '20px'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
      outline: '1px solid slategrey'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#fff',
      outline: '1px solid slategrey'
    }
  };
  const isValidPage = (page) => {
    return page !== null && page !== undefined && page !== '' && page !== '-';
  };
  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };
  return (
    <Grid
      container
      sx={{
        padding: '1rem',
        height: { xs: '87vh', xl: '60vh' },
        overflowY: { xs: 'auto', lg: 'hidden' },
        '&::-webkit-scrollbar': {
          width: '.2em',
          height: '0em',
          borderRadius: '20px'
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
          outline: '1px solid slategrey'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#fff',
          outline: '1px solid slategrey'
        }
      }}>
      {loading ? (
        <Grid container sx={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <CircularProgress size={45} sx={{ color: Colors.DARKBLUE }} />
        </Grid>
      ) : (
        <>
          <Grid
            item
            xs={12}
            sx={{
              mt: '.5rem',
              marginBottom: '1.5rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItem: 'center'
            }}>
            <Typography variant="h6" sx={{ color: Colors.WHITE }}>
              {navigationValue === 0
                ? 'Compare PO to Invoice'
                : navigationValue === 1
                ? 'Compare Delivery Ticket to Invoice'
                : 'Supporting Document'}
            </Typography>
            {(annotationType === 'Invoice' || annotationType === 'Receipt') &&
              deliveryTicketUpload &&
              poMatchPermission && (
                <Navigation
                  value={navigationValue}
                  setValue={setNavigationValue}
                  label1="PO"
                  label2="DT"
                />
              )}
          </Grid>
          {isEmpty(response) ? (
            <Grid
              container
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                color: Colors.WHITE
              }}>
              {NO_DOCUMENTS}
            </Grid>
          ) : (
            <Grid
              container
              sx={{
                justifyContent: { xs: 'center', lg: 'space-between' },
                alignItems: 'center'
              }}>
              {navigationValue === 0 ? (
                <Grid
                  item
                  xs={12}
                  md={5}
                  sx={{
                    padding: '1.5rem',
                    backgroundColor: Colors.NAV_BLACK,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '10px'
                  }}>
                  <Box
                    sx={{
                      width: { xs: '90%', sm: '60%', md: '90%', lg: '100%' }
                    }}>
                    <GeneratePoCard
                      textSize={{ xs: '8px', lg: '10px', xl: '12px' }}
                      height={{ xs: '47vh', md: '59vh', lg: '61vh', xl: '42vh' }}
                      parentHeight="100%"
                      cursor={smallScreen ? 'pointer' : 'auto'}
                      onClick={smallScreen ? handleCardOpen : handleCardOpen}
                      documentContentMapped={filteredArray}
                      documentLineItemsMapped={documentLineItemsMapped}
                      lineItemsPerPage={lineItemsPerPage}
                      totalCardPages={totalCardPages}
                      show={true}
                      width="auto"
                    />
                    <Modal
                      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      open={cardOpen}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description">
                      <Grid
                        sx={{
                          height: { xs: '60vh', md: '67vh', xl: '50vh' },
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'center',
                          transform:
                            documentLineItemsMapped?.length > 10 ? 'scale(1.20)' : 'scale(1.3)',
                          cursor: 'pointer'
                        }}
                        onClick={handleClose}>
                        <GeneratePoCard
                          textSize={{ xs: '8px', lg: '10px', xl: '12px' }}
                          cursor="pointer"
                          height={{ xs: '47vh', md: '60vh', xl: '45vh' }}
                          onClick={handleClose}
                          documentContentMapped={filteredArray}
                          documentLineItemsMapped={documentLineItemsMapped}
                          lineItemsPerPage={lineItemsPerPage}
                          totalCardPages={totalCardPages}
                          width={{ xs: '60vw', md: '20vw', xl: '16vw' }}
                          show={true}
                        />
                      </Grid>
                    </Modal>
                  </Box>
                </Grid>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    md={5}
                    sx={{
                      padding: '1.5rem',
                      height: { xs: '72vh', xl: '48vh' },
                      backgroundColor: Colors.NAV_BLACK,
                      borderRadius: '10px'
                    }}>
                    <Grid container item xs={12}>
                      <Typography sx={{ color: Colors.TOURQUISE, mb: '1rem' }}>
                        {INVOICE_LINEITEMS}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        height: '93%',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        '&::-webkit-scrollbar': {
                          width: '.2em',
                          height: '0em',
                          borderRadius: '20px'
                        },
                        '&::-webkit-scrollbar-track': {
                          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
                          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
                          outline: '1px solid slategrey'
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '#fff',
                          outline: '1px solid slategrey'
                        }
                      }}>
                      {isEmpty(supportindDocLineitems) ? (
                        <Grid
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            color: Colors.WHITE,
                            alignItems: 'center',
                            height: '100%'
                          }}>
                          <Typography>{NO_LINEITEMS}</Typography>
                        </Grid>
                      ) : (
                        <>
                          <Grid
                            container
                            className="value-button"
                            style={{ position: 'sticky', top: 0 }}>
                            <Grid item xs={6} md={9}>
                              <p style={divStyle}>
                                <DescriptionOutlined
                                  sx={{
                                    color: Colors.TOURQUISE,
                                    fontSize: '1rem',
                                    marginRight: '.2rem'
                                  }}
                                />
                                {DESCRIPTION}
                              </p>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              md={3}
                              sx={{ display: 'flex', justifyContent: 'center' }}>
                              <p style={divStyle}>
                                <ShoppingCart
                                  sx={{
                                    color: Colors.TOURQUISE,
                                    fontSize: '1rem',
                                    marginRight: '.2rem'
                                  }}
                                />
                                {QUANTITY}
                              </p>
                            </Grid>
                          </Grid>

                          {supportindDocLineitems?.map((lineItem, index) => (
                            <Grid container key={index} className="value-button">
                              <Grid item xs={6} md={9}>
                                {lineItem?.map((childItem, idx) =>
                                  childItem?.name === 'Description' ? (
                                    <Grid
                                      key={idx}
                                      item
                                      xs={12}
                                      sx={{
                                        ...scrollStyle,
                                        cursor: isValidPage(childItem?.page) ? 'pointer' : 'default'
                                      }}
                                      onClick={() => {
                                        if (isValidPage(childItem?.page)) {
                                          setDtIndex(childItem?.page);
                                          handleItemClick(
                                            'button_' + childItem?.value + '**%**' + index + idx
                                          );
                                        }
                                      }}>
                                      <p style={divStyle}>
                                        <Check
                                          sx={{
                                            fontSize: '1rem',
                                            color: childItem?.match
                                              ? Colors.TOURQUISE
                                              : Colors.WHITE,
                                            marginRight: '.5rem'
                                          }}
                                        />{' '}
                                        {childItem?.value || '-'}
                                      </p>
                                    </Grid>
                                  ) : null
                                )}
                              </Grid>

                              <Grid
                                item
                                xs={6}
                                md={3}
                                sx={{ display: 'flex', justifyContent: 'center' }}>
                                {lineItem?.map((childItem, idx) =>
                                  childItem?.name === 'Quantity' ? (
                                    <Grid
                                      key={idx}
                                      item
                                      xs={12}
                                      sx={{
                                        ...scrollStyle,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        cursor: isValidPage(childItem?.page) ? 'pointer' : 'default'
                                      }}
                                      onClick={() => {
                                        if (isValidPage(childItem?.page)) {
                                          setDtIndex(childItem?.page);
                                          handleItemClick(
                                            'button_' + childItem?.value + '**%**' + index + idx
                                          );
                                        }
                                      }}>
                                      <p style={divStyle}>{childItem?.value || '-'}</p>
                                    </Grid>
                                  ) : null
                                )}
                              </Grid>
                            </Grid>
                          ))}
                        </>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
              <>
                <Hidden mdDown>
                  <CompareArrowsIcon sx={{ color: Colors.TOURQUISE, fontSize: '3rem' }} />
                </Hidden>
                {navigationValue === 0 ? (
                  <Grid
                    item
                    xs={12}
                    md={5}
                    sx={{
                      padding: '1.5rem',
                      height: { xs: '72vh', xl: '48vh' },
                      objectFit: 'contain',
                      display: 'flex',
                      backgroundColor: Colors.NAV_BLACK,
                      justifyContent: 'center',
                      borderRadius: '10px'
                    }}>
                    {images?.length > 0 && (
                      <img
                        className={`${selectedImage ? 'image-container enlarged' : ''}`}
                        src={images[index]}
                        alt={index}
                        style={{
                          cursor: 'pointer',
                          height: '100%',
                          objectFit: 'contain',
                          maxHeight: '100%',
                          maxWidth: '100%',
                          aspectRatio: 1
                        }}
                        onClick={handleImageClick}
                      />
                    )}
                    {images?.length > 1 && (
                      <PaginationButtons
                        disableFirstButton={index === 0}
                        disableSecondButton={index === images?.length - 1}
                        slideLeft={slideLeft}
                        slideRight={slideRight}
                        index={index}
                        images={images}
                      />
                    )}
                  </Grid>
                ) : (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={5}
                      sx={{
                        padding: '1.5rem',
                        height: { xs: 'max-content', md: '72vh', xl: '48vh' },
                        display: 'flex',
                        backgroundColor: Colors.NAV_BLACK,
                        justifyContent: 'center',
                        borderRadius: '10px'
                      }}>
                      {dTUrl?.length > 0 && (
                        <SupportingDocumentWithBoundingBoxes
                          lineItemsArray={supportindDocLineitems}
                          dTUrl={dTUrl}
                          dtIndex={dtIndex}
                          setImageDimensions={setImageDimensions}
                          imageDimensions={imageDimensions}
                          selectedItemIndex={selectedItemIndex}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {dTUrl?.length > 1 && (
                        <PaginationButtons
                          disableFirstButton={dtIndex === 0}
                          disableSecondButton={dtIndex === dTUrl?.length - 1}
                          slideLeft={slideLeftDT}
                          slideRight={slideRightDT}
                          index={dtIndex}
                          images={dTUrl}
                        />
                      )}
                    </Grid>
                  </>
                )}
              </>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default SupportingDocument;
