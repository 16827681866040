import React, { useState, useEffect } from 'react';
import { isNaN, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  Select,
  Typography,
  CircularProgress
} from '@mui/material';
import { ErrorOutline, InfoOutlined, Sync } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import LevelCards from '../components/levelCards';
import { Colors } from '../config/default';
import IntegrationButton from '../components/integrationButton';
import approvalIcon from '../assets/approvalFlow.png';

import {
  addPolicy,
  getOwnerUsers,
  getPolicy,
  deletePolicy,
  getGlInfo,
  syncManagersFromErp
} from '../services/services';
import { BootstrapInput } from '../services/common';
import Prompt from '../components/prompt';
import { useToast } from '../toast/toastContext';
import { policyPage } from '../constants/appConstants';
export default function Policy() {
  const {
    ADD_POLICY,
    DELETE,
    CONFIRM_DELETE_POLICY,
    POLICY_LEVEL,
    POLICY_BASIS,
    TOTAL,
    VENDOR,
    JOBID,
    ADD_LEVELS,
    REMOVE_LEVEL,
    POLICY_NOT_ACTIVE,
    POLICY_AVAILABLE,
    FETCH_MANAGERS
  } = policyPage;
  const MenuItems = [
    { name: TOTAL, value: 'total' },
    { name: VENDOR, value: 'vendor' },
    { name: JOBID, value: 'jobId' }
  ];
  const [apiData, setApiData] = useState([]);
  const [getUserRes, setGetUserRes] = useState([]);
  const [policyBase, setPolicyBase] = useState('jobId');
  const [disableBtn, setDisableBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allEmails, setAllEmails] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [policyExist, setPolicyExist] = useState(false);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [disableLevelBtn, setDisableLevelBtn] = useState(false);
  const [savedPolicy, setSavedPolicy] = useState({});
  const [savedBase, setSavedBase] = useState('');
  const storedChecked = localStorage.getItem('switchState');
  const [checked, setChecked] = useState(storedChecked === 'true');
  const [prevChecked, setPrevChecked] = useState(storedChecked === 'true');
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const activeIntegration = userDetails?.activeIntegration;
  const erpType = userDetails?.user?.erpType;
  const companyId = userDetails?.company?._id;
  const [vendors, setVendors] = useState([]);
  const [jobIds, setJobIds] = useState([]);
  const [open, setOpen] = useState(false);
  const [fetchingManagers, setFetchingManagers] = useState(false);

  const [policyData, setPolicyData] = useState({
    companyApprovalPolicy: checked,
    base: policyBase,
    levels: []
  });

  const handleOpen = () => setOpen(true);
  const { showToast } = useToast();
  const getInformation = async () => {
    const res = await getGlInfo(userDetails?.user?.email);
    if (res?.data?.metadata?.status === 'SUCCESS') {
      setVendors(res?.data?.payload?.data?.vendorsList?.map((item) => item?.name));
      const sortedJobIds = res?.data?.payload?.data?.jobIdsList
        ?.sort((a, b) => {
          if (erpType === 'Foundation') {
            return a?.deptNum - b?.deptNum;
          }
          return a?.id - b?.id;
        })
        ?.map((item) =>
          erpType === 'Foundation'
            ? item?.deptNum + ' - ' + item?.name
            : item?.id + ' - ' + item?.name
        );
      setJobIds(sortedJobIds);
    }
  };

  const getUsers = async () => {
    setIsUserLoading(true);
    const result = await getOwnerUsers();
    if (result?.status === 200) {
      const filteredUsers = result?.data?.payload?.data?.filter((item) => {
        return item?.role !== 'Accountant' && item?.signUp === true;
      });
      const emails = filteredUsers?.map((item) => item?.email);
      setAllEmails(filteredUsers);
      setApiData(emails);
      setGetUserRes(emails);
    }
    setIsUserLoading(false);
  };
  const getOwnerPolicy = async () => {
    setIsLoading(true);
    const response = await getPolicy();
    if (response?.status === 200 && response?.data?.payload?.data?.policy?.policies) {
      const policy = response?.data?.payload?.data?.policy?.policies;
      const mappedLevels = policy?.levels?.map((level, index) => ({
        levelNumber: level?.levelNumber,
        requiredApprovals: level?.requiredApprovals,
        min: level?.min,
        max: level?.max,
        approvals: level?.approvals ? level?.approvals?.map((approval) => approval?.users) : [],
        match: level?.match || []
      }));
      setChecked(response?.data?.payload?.data?.approvalFlowEnabled);
      setPolicyBase(policy?.base);
      setSavedBase(policy?.base);
      setSavedPolicy({
        companyApprovalPolicy: response?.data?.payload?.data?.approvalFlowEnabled,
        base: policy?.base,
        levels: mappedLevels
      });
      setPolicyData({
        companyApprovalPolicy: response?.data?.payload?.data?.approvalFlowEnabled,
        base: policy?.base,
        levels: mappedLevels
      });
      setPolicyExist(true);
    } else {
      setChecked(response?.data?.payload?.data?.approvalFlowEnabled);
      setPolicyData({
        companyApprovalPolicy: response?.data?.payload?.data?.approvalFlowEnabled,
        base: policyBase,
        levels: []
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getOwnerPolicy();
    getUsers();
    getInformation();
  }, []);
  useEffect(() => {
    setPolicyData((prevData) => ({
      ...prevData,
      companyApprovalPolicy: checked
    }));
  }, [checked]);

  useEffect(() => {
    const disableCondition =
      !isEmpty(policyData?.levels) &&
      policyData?.levels?.some((item) => {
        if (
          (policyBase === 'total' &&
            (item?.max === null ||
              (item?.max !== 0 && item.max < item?.min) ||
              isNaN(item?.max))) ||
          ((policyBase === 'vendor' || policyBase === 'jobId') && isEmpty(item?.match)) ||
          item?.approvals.some((approval) => approval.length === 0) ||
          item?.requiredApprovals !== item?.approvals?.length ||
          Array.from({ length: item?.requiredApprovals }).some(
            (_, index) => !item?.approvals[index]
          )
        ) {
          return true;
        }

        return false;
      });
    const disableAddLevel =
      !isEmpty(policyData?.levels) &&
      policyData?.levels?.some((item) => {
        if (policyBase === 'total' && item?.max === 0) {
          return true;
        }
        return false;
      });
    setDisableBtn(disableCondition);
    setDisableLevelBtn(disableAddLevel);
  }, [policyData, policyData?.levels]);
  const handleLevelData = (levelData) => {
    setPolicyData((prevData) => {
      let newLevels = [...prevData.levels];
      const newLevelsIndex = newLevels.findIndex(
        (level) => level?.levelNumber === levelData?.levelNumber
      );
      if (newLevelsIndex === -1) {
        newLevels.push(levelData);
      } else {
        if (newLevels?.length > newLevelsIndex + 1) {
          newLevels[newLevelsIndex + 1].min = levelData?.max + 1;
        }
        newLevels[newLevelsIndex] = levelData;
      }

      return {
        ...prevData,
        levels: newLevels
      };
    });
  };
  const handleClick = () => {
    const lastLevel = policyData?.levels[policyData?.levels?.length - 1];
    const levelData = {
      levelNumber: policyData?.levels?.length > 0 ? lastLevel?.levelNumber + 1 : 1,
      requiredApprovals: 0,
      min: policyData?.levels?.length > 0 ? lastLevel?.max + 1 : 1,
      max: policyBase === 'vendor' || policyBase === 'jobId' ? 0 : null,
      approvals: [],
      match: []
    };
    setPolicyData((prevData) => {
      return {
        ...prevData,
        levels: [...prevData?.levels, levelData]
      };
    });
  };

  const handleSavePolicy = async (check) => {
    setLoading(true);
    const transformedLevels = policyData?.levels?.map((level) => ({
      ...level,
      approvals: level?.approvals?.map((users) => ({ users }))
    }));

    const transformedPolicyData = {
      ...policyData,
      levels: transformedLevels,
      companyApprovalPolicy: checked
    };
    localStorage.setItem('switchState', checked.toString());

    const result = await addPolicy(transformedPolicyData);
    if (result?.status === 200) {
      if (!check) {
        showToast(result?.data?.metadata?.message, 'success');
      }
      getOwnerPolicy();
    } else {
      showToast(
        result?.data?.metadata?.message || result?.response?.data?.metadata?.message,
        'error'
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (prevChecked !== checked && !isEmpty(policyData?.levels)) {
      handleSavePolicy(true);
      setPrevChecked(checked);
    }
  }, [checked, prevChecked]);

  const handleRemoveLastLevel = () => {
    setPolicyData((prevData) => {
      const newLevels = [...prevData.levels];
      const removedLevel = newLevels.pop();
      removedLevel?.approvals?.flat();

      let newArray = [];
      prevData?.levels?.forEach((level) => {
        level?.approvals?.forEach((email) => {
          newArray = [...newArray, ...email];
        });
      });
      const filteredEmails =
        allEmails &&
        allEmails?.filter((item) => {
          return !newArray.includes(item?.email);
        });
      const emails = filteredEmails?.map((item) => item?.email);
      removedLevel.match = [];
      setApiData(emails || []);

      return {
        ...prevData,
        levels: newLevels
      };
    });
  };
  const handleDeletePolicy = async (e) => {
    setDeleting(true);
    e.preventDefault();
    const deleteResult = await deletePolicy();
    if (deleteResult?.status === 200) {
      showToast(deleteResult?.data?.metadata?.message, 'success');
      getOwnerPolicy();
      setPolicyExist(false);
      setPolicyData({
        companyApprovalPolicy: false,
        base: 'total',
        levels: []
      });
      setOpen(false);
    } else {
      showToast(
        deleteResult?.data?.metadata?.message || deleteResult?.response?.data?.metadata?.message,
        'error'
      );
    }
    setDeleting(false);
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        borderRadius: '10px'
      }
    }
  };

  const fetchManagersFromErp = async () => {
    setFetchingManagers(true);
    const result = await syncManagersFromErp(companyId);
    if (result?.status === 200) {
      const mappedLevels = result?.data?.payload?.data?.levels?.map((level, index) => ({
        levelNumber: level?.levelNumber,
        requiredApprovals: level?.requiredApprovals,
        min: level?.min,
        max: level?.max,
        approvals: level?.approvals ? level?.approvals?.map((approval) => approval?.users) : [],
        match: level?.match || []
      }));
      setPolicyData((prevData) => ({
        ...prevData,
        levels: mappedLevels
      }));
      if (isEmpty(result?.data?.payload?.data?.levels)) {
        showToast('Registered Managers does not exist in Erp', 'error');
      } else {
        showToast(result?.data?.metadata?.message, 'success');
      }
    }

    setFetchingManagers(false);
  };
  return (
    <>
      {isLoading || isUserLoading || loading ? (
        <Grid
          container
          sx={{
            height: '70vh',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <CircularProgress size={150} color="primary" />
        </Grid>
      ) : isEmpty(getUserRes) && !policyExist ? (
        <Grid
          container
          sx={{
            height: '75vh',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Box
            sx={{
              backgroundColor: Colors.NAV_BLACK,
              padding: '.5em',
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '.8em',
              color: Colors.TEXTGREY
            }}>
            <InfoOutlined sx={{ color: Colors.TEXTGREY, marginRight: '.3em', fontSize: '1.3em' }} />
            {POLICY_AVAILABLE}
          </Box>
        </Grid>
      ) : (
        <Grid
          container
          item
          sx={{
            justifyContent: { sm: 'center', lg: 'flex-start' },
            marginTop: '1rem',
            height: { xs: '65vh', sm: '70vh' },
            overflowY: { xs: 'scroll', lg: 'visible' },
            '&::-webkit-scrollbar': {
              width: '0em',
              height: '0em',
              borderRadius: '20px'
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
              outline: '1px solid slategrey'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#fff',
              outline: '1px solid slategrey'
            }
          }}>
          <Grid
            item
            xs={12}
            md={3.4}
            lg={2.4}
            xl={2}
            sx={{
              display: { xs: 'flex', sm: 'block' },
              justifyContent: { xs: 'center', md: 'flex-start' },
              ml: { sm: '1em', md: '2em', lg: '0' },
              mt: { xs: '1em', md: '0' },
              marginBottom: { xs: '1em', md: '0em' }
            }}>
            <IntegrationButton
              width="13em"
              cardIcon={approvalIcon}
              cardTitle="APPROVAL POLICY"
              toggle={true}
              buttonName={checked ? 'ACTIVE' : 'INACTIVE'}
              activity={checked ? Colors.DARKBLUE : Colors.RED}
              subTitle={ADD_POLICY}
              setChecked={setChecked}
              checked={checked}
              cursor={false}
            />
          </Grid>
          {checked ? (
            <Grid
              container
              item
              xs={12}
              md={7.8}
              lg={9.6}
              xl={7}
              sx={{
                height: 'fit-content',
                ml: { xs: '1em', md: '0em' },
                backgroundColor: Colors.NAV_BLACK,
                borderRadius: '10px',
                boxShadow: 'none'
              }}>
              <Grid
                item
                container
                sx={{
                  height: '4rem',
                  background: Colors.Canvas_BG,
                  alignItems: 'center',
                  paddingLeft: '1.3em',
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px'
                }}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                  }}>
                  {policyExist && savedBase === policyBase && (
                    <Button
                      variant="contained"
                      sx={{
                        color: Colors.WHITE,
                        marginRight: '1rem',
                        width: '10rem',
                        height: '2.2rem',
                        borderRadius: '10px',
                        fontSize: '.875rem',
                        backgroundColor: Colors.RED,
                        '&:hover': {
                          backgroundColor: Colors.RED
                        }
                      }}
                      onClick={handleOpen}>
                      {DELETE}
                    </Button>
                  )}
                  <Prompt
                    title="Delete Policy"
                    description={CONFIRM_DELETE_POLICY}
                    leftButton="Cancel"
                    rightButton={DELETE}
                    setOpen={setOpen}
                    open={open}
                    handleClick={handleDeletePolicy}
                    loading={deleting}
                  />
                  <Button
                    disabled={disableBtn}
                    variant="contained"
                    sx={{
                      color: Colors.WHITE,
                      marginRight: '1rem',
                      width: '10rem',
                      height: '2.2rem',
                      color: Colors.WHITE,
                      borderRadius: '10px',
                      fontSize: '.875rem',
                      borderColor: Colors.DARKBLUE,
                      mr: '1.5em',
                      '&:hover': {
                        borderColor: Colors.DARKBLUE
                      }
                    }}
                    onClick={() => handleSavePolicy(false)}>
                    {loading ? <CircularProgress size={22} color="inherit" /> : 'Save'}
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  padding: '1em'
                }}>
                <Card
                  sx={{
                    width: '100%',
                    borderRadius: '10px',
                    backgroundColor: Colors.NAV_BLACK,
                    padding: '1rem',
                    boxShadow: 'none'
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      height: '2rem',
                      justifyContent: 'space-between'
                    }}>
                    <Typography
                      sx={{ fontWeight: '500', color: Colors.WHITE, fontSize: '.875rem' }}>
                      {POLICY_LEVEL}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex'
                    }}>
                    <Grid container item xs={4} sx={{ alignItems: 'center', gap: '1.5em' }}>
                      <div>
                        <Typography sx={{ color: Colors.LIGHTGREY, fontSize: '0.75em' }}>
                          {POLICY_BASIS}
                        </Typography>
                      </div>
                      <div>
                        <Select
                          variant="outlined"
                          value={policyBase}
                          onChange={(e) => {
                            setPolicyBase(e.target.value);
                            if (e.target.value !== savedBase) {
                              setPolicyData((prevData) => {
                                return {
                                  ...prevData,
                                  base: e.target.value,
                                  levels: []
                                };
                              });
                            } else {
                              setPolicyData(savedPolicy);
                            }
                          }}
                          MenuProps={MenuProps}
                          input={<BootstrapInput />}
                          sx={{
                            width: '9rem',
                            borderRadius: '0.6em',
                            outline: 'none',
                            color: Colors.TEXTGREY,
                            height: '2rem',
                            fontSize: '0.75em',
                            padding: '1em 0em',
                            textAlign: 'left',
                            border: '.7px solid #BABABA'
                          }}>
                          {MenuItems?.map((item) => {
                            return (
                              <MenuItem
                                sx={{
                                  color: Colors.TEXTGREY,
                                  borderRadius: '8px',
                                  margin: '0px 8px',
                                  '&:hover': {
                                    borderRadius: '7px',
                                    backgroundColor: Colors.BG_BLACK
                                  }
                                }}
                                value={item?.value}>
                                {item?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </div>
                    </Grid>
                    <Grid
                      item
                      container
                      sx={{
                        gap: { xs: '10px', sm: '1rem' },
                        justifyContent: 'flex-end',
                        alignItems: { xs: 'flex-end', sm: 'flex-start' }
                      }}>
                      {policyBase === 'jobId' && activeIntegration === 'Foundation' && (
                        <Button
                          disabled={fetchingManagers}
                          variant="outlined"
                          sx={{
                            width: { xs: '5rem', sm: '8rem', md: '10rem' },
                            height: '2rem',
                            color: Colors.WHITE,
                            borderRadius: '10px',
                            padding: { xs: 0, sm: 'auto' },
                            fontSize: { xs: '8px', sm: '10px', md: '.75rem' },
                            borderColor: Colors.RED,
                            borderColor: Colors.DARKBLUE,
                            '&:hover': {
                              borderColor: Colors.DARKBLUE
                            }
                          }}
                          onClick={fetchManagersFromErp}>
                          {fetchingManagers ? (
                            <CircularProgress size={22} color="inherit" />
                          ) : (
                            <>
                              <Sync
                                sx={{
                                  display: { xs: 'none', sm: 'block' },
                                  mr: '0.2em',
                                  fontSize: '16px',
                                  color: Colors.LIGHTBLUE
                                }}
                              />
                              {FETCH_MANAGERS}
                            </>
                          )}
                        </Button>
                      )}
                      <Button
                        disabled={disableBtn || isEmpty(apiData) || disableLevelBtn}
                        variant="outlined"
                        sx={{
                          width: { xs: '5rem', sm: '8rem', md: '10rem' },
                          height: '2rem',
                          color: Colors.WHITE,
                          borderRadius: '10px',
                          padding: { xs: 0, sm: 'auto' },
                          fontSize: { xs: '8px', sm: '10px', md: '.75rem' },
                          borderColor: Colors.RED,
                          borderColor: Colors.DARKBLUE,
                          '&:hover': {
                            borderColor: Colors.DARKBLUE
                          }
                        }}
                        onClick={handleClick}>
                        <AddIcon
                          sx={{
                            display: { xs: 'none', sm: 'block' },
                            mr: '0.2em',
                            fontSize: '16px',
                            color:
                              disableBtn || isEmpty(apiData) || disableLevelBtn
                                ? 'auto'
                                : Colors.LIGHTBLUE
                          }}
                        />
                        {ADD_LEVELS}
                      </Button>
                      <Button
                        disabled={isEmpty(policyData?.levels)}
                        variant="outlined"
                        sx={{
                          width: { xs: '5rem', sm: '8rem', md: '10rem' },
                          height: '2rem',
                          color: Colors.WHITE,
                          borderRadius: '10px',
                          padding: { xs: 0, sm: 'auto' },
                          fontSize: { xs: '8px', sm: '10px', md: '.75rem' },
                          borderColor: Colors.RED,
                          '&:hover': {
                            borderColor: Colors.RED,
                            background: ' rgba(196, 75, 108, 0.08)'
                          }
                        }}
                        onClick={handleRemoveLastLevel}>
                        <RemoveIcon
                          sx={{
                            display: { xs: 'none', sm: 'block' },
                            mr: '0.2em',
                            fontSize: '16px',
                            color: isEmpty(policyData?.levels) ? 'auto' : Colors.RED
                          }}
                        />
                        {REMOVE_LEVEL}
                      </Button>
                    </Grid>
                  </div>
                  <Grid
                    sx={{
                      display: 'flex',
                      mt: { xs: '1em', sm: '1em' },
                      flexDirection: 'column',
                      height: { xs: 'fit-content', sm: '18em' },
                      overflowY: 'auto',
                      overflowX: 'auto',
                      '&::-webkit-scrollbar': {
                        width: { xs: '4px', sm: '7px' },
                        height: '0em',
                        borderRadius: '20px'
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
                        outline: '1px solid slategrey'
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#fff',
                        outline: '1px solid slategrey'
                      }
                    }}>
                    {policyData?.levels?.map((item, i) => {
                      return (
                        <LevelCards
                          key={i}
                          index={i}
                          item={item}
                          policyData={policyData}
                          setData={(levelData) => handleLevelData(levelData)}
                          setAllEmails={setAllEmails}
                          allEmails={allEmails}
                          policyBase={policyBase}
                          vendors={vendors}
                          jobIds={jobIds}
                        />
                      );
                    })}
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              item
              xs={12}
              sm={11}
              md={10}
              lg={9}
              xl={7}
              sx={{
                alignItems: 'center',
                gap: '1em',
                justifyContent: 'center',
                ml: '2em',
                height: '75vh'
              }}>
              <Box
                sx={{
                  backgroundColor: Colors.NAV_BLACK,
                  padding: '.5em',
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '.8em',
                  color: Colors.TEXTGREY
                }}>
                <ErrorOutline
                  sx={{ color: Colors.TEXTGREY, marginRight: '.3em', fontSize: '1.3em' }}
                />
                {POLICY_NOT_ACTIVE}
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}
