import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { isEmpty } from 'lodash';

import { Grid, CircularProgress } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import {
  CallReceivedOutlined,
  DescriptionOutlined,
  InsertDriveFileOutlined,
  HighlightAltOutlined
} from '@mui/icons-material';

import Layout from '../components/layout';
import DataTable from '../components/dataTable';
import theme from '../theme';
import HeaderSection from '../components/headerSection';
import { parentTabs, childTabs, featuresTabs, dashboardTabs, formatDate } from '../services/common';
import {
  getSelectedDocs,
  syncData,
  getUserInfo,
  createCsv,
  searchAttachmentById,
  searchbyChequeNumber,
  getAllBatches
} from '../services/services';
import { get_queue, user_information, get_tabs } from '../redux/actions/action';
import MyIntegerations from '../components/myIntegration';
import ManualSync from './manualSync';
import Policy from './policy';
import Dashboard from './dashboard';
import { useToast } from '../toast/toastContext';
import Attachments from './attachments';
import UploadAttachments from './uploadAttachments';
import ErpDocuments from './erpDocuments';
import AdminDataTable from '../components/adminDataTable';
import MuiModal from '../components/muiModel';
import { tableComponent } from '../constants/appConstants';
import { Colors } from '../config/default';

function HomePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { RECIEVED_AT, NO_OF_INVOICES, FILE_NAME, ACTION } = tableComponent;
  const globalActiveTab = useSelector((state) => state?.tabs?.tabs);
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const companyId = userDetails?.company?._id;
  const activeIntegration = useSelector((state) => state?.userInfo?.userInfo?.activeIntegration);
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const erpType = userDetails?.user?.erpType;
  const serviceAndEquipmentPermission = userDetails?.company?.serviceCodeAndEquipmentNo;
  const hideManualSyncing = [{ name: 'My Integration' }];
  const hideMyIntegration = [{ name: 'Manual Syncing' }];
  const showMyIntegration = [{ name: 'My Integration' }, { name: 'Manual Syncing' }];
  let integrationTabs =
    erpType === 'DeltekComputerEase'
      ? hideMyIntegration
      : activeIntegration
      ? hideManualSyncing
      : showMyIntegration;
  if (erpType === 'Foundation' && activeIntegration !== '') {
    integrationTabs = [...integrationTabs, { name: 'Search Integration' }];
  }
  const parentActiveTab = globalActiveTab?.activeTab;

  const [activeTab, setActiveTab] = useState(parentActiveTab || parentTabs[0]?.name);
  const [childActiveTab, setChildActiveTab] = useState(
    globalActiveTab?.documentChildActiveTab || childTabs[0]?.name
  );
  const [integrationChildTab, setIntegrationChildTab] = useState(
    globalActiveTab?.IntegrationChildActiveTab || integrationTabs[0]?.name
  );
  const [featuresChildTab, setFeaturesChildTab] = useState(featuresTabs[0]?.name);
  const [dashboardChildTabs, setDashboardChildTabs] = useState(dashboardTabs[0]?.name);
  const [searchAttachments, setSearchAttachments] = useState('');
  const [createCsvLoading, setCreateCsvLoading] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [recordExist, setRecordExist] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const pendingQueues = useSelector((state) => state?.queue?.queue[0]);
  const [erpLoading, setErpLoading] = useState(false);
  const [searchProducts, setSearchProducts] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dataAccordingTabs, setDataAccordingTabs] = useState([]);
  const { showToast } = useToast();
  const [isAttachmentSearched, setIsAttachmentSearched] = useState(false);
  const [searchedResult, setSearchedResult] = useState([]);
  const isSearched = localStorage?.getItem('instrumentId');
  const [uploadAttachment, setUploadAttachment] = useState(
    !isEmpty(isSearched) || isSearched !== '' ? false : true
  );
  const [instrumentId, setInstumentId] = useState('');
  const [isChequeLoading, setIsChequeLoading] = useState(false);
  const [searchedCheques, setSearchedCheques] = useState([]);
  const [chequeNum, setChequeNum] = useState('');
  const [isChequeSearched, setIsChequeSearched] = useState(false);
  const [selectedRowsHasPO, setSelectedRowsHasPO] = useState(true);
  const callSource = userRole === 'Vendor' ? 'api' : 'app';
  const [syncElement, setSyncElement] = useState([]);
  const [batchDocumentList, setBatchDocumentList] = useState([]);
  const [batchDocs, setBatchDocs] = useState([]);
  const [batchDocsLoading, setBatchDocsLoading] = useState(false);
  const [documentCount, setDocumentCount] = useState({});
  const getbatches = async () => {
    const batches = await getAllBatches(companyId);
    if (batches?.status === 200) {
      setBatchDocumentList(batches?.data?.payload?.data || []);
    } else if (batches?.response?.status === 401) {
      localStorage.clear();
      navigate('/');
    }
  };
  const LoadBatchDocs = () => {
    setBatchDocsLoading(true);
    getbatches();
    setBatchDocsLoading(false);
  };
  useEffect(() => {
    const Data = batchDocumentList?.map((item) => ({
      id: item?.id,
      file: item?.fileName || '-',
      noOfInvoices: item?.invoices || '-',
      date: formatDate(item?.createdAt)
    }));
    setBatchDocs(Data);
  }, [batchDocumentList]);
  const Tabs = {
    activeTab: activeTab,
    documentChildActiveTab: childActiveTab,
    IntegrationChildActiveTab: integrationChildTab
  };

  const isConstructionCompany = useSelector(
    (state) => state?.userInfo?.userInfo?.user?.constructionCompany
  );
  const cognitoUsername = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.accessToken?.payload?.username
  );

  const allQueuesData = async () => {
    setLoading(true);
    const result = await getSelectedDocs(callSource);
    if (result?.response?.status === 401) {
      localStorage.clear();
      navigate('/');
    }
    dispatch(get_queue(result?.data?.payload?.data));
    setLoading(false);
  };

  const intervalCall = async () => {
    const result = await getSelectedDocs(callSource);
    if (result?.response?.status === 401) {
      localStorage.clear();
      navigate('/');
    }
    dispatch(get_queue(result?.data?.payload?.data));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      intervalCall();
      getbatches();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    dispatch(get_tabs(Tabs));
  }, [activeTab, childActiveTab, integrationChildTab]);

  useEffect(() => {
    setActiveTab(parentActiveTab || parentTabs[0]?.name);
    setChildActiveTab(globalActiveTab?.documentChildActiveTab || childTabs[0]?.name);
    setIntegrationChildTab(globalActiveTab?.IntegrationChildActiveTab || integrationTabs[0]?.name);
  }, [
    parentActiveTab,
    globalActiveTab?.documentChildActiveTab,
    globalActiveTab?.IntegrationChildActiveTab
  ]);

  const handleCreateCSV = async (e) => {
    e.preventDefault();
    setCreateCsvLoading(true);
    const params = {
      documentIds: checkedIds
    };
    const response = await createCsv(params);
    if (response?.status === 200) {
      showToast(response?.data?.metadata?.message, 'success');
      setCheckedIds([]);
      allQueuesData();
      setCreateCsvLoading(false);
    } else if (response?.response?.status === 401) {
      localStorage.clear();
      navigate('/');
    } else {
      showToast(response?.data?.metadata?.message, 'error');
    }
    setCreateCsvLoading(false);
  };
  const constructionData = [
    'vendors',
    'accounts',
    'jobIds',
    'costIds',
    'costType',
    'purchaseOrder',
    'phase',
    'employees',
    'subContract'
  ];

  const serviceData = [
    'vendors',
    'accounts',
    'purchaseOrder',
    'employees',
    'equipment',
    'serviceCode',
    'subContract'
  ];

  let SyncArray;
  if (isConstructionCompany) {
    SyncArray = serviceAndEquipmentPermission
      ? [...constructionData, 'equipment', 'serviceCode']
      : constructionData;
  } else {
    SyncArray = serviceAndEquipmentPermission
      ? serviceData
      : ['vendors', 'accounts', 'purchaseOrder', 'employees'];
  }

  const handleSyncErp = async (integtaion, selectedValue) => {
    setErpLoading(true);
    localStorage.setItem('erpType', userDetails?.activeIntegration);
    let params = {
      data: selectedValue || SyncArray
    };

    const result = await syncData(params);
    if (result?.status === 200) {
      const getUserresult = await getUserInfo(cognitoUsername);
      if (getUserresult?.status === 200) {
        dispatch(user_information(getUserresult?.data?.payload?.data));
      }
      showToast(result?.data?.metadata?.message, 'success');
      setErpLoading(false);
    } else if (result?.response?.status === 401) {
      localStorage.clear();
      navigate('/');
    } else {
      showToast(
        result?.data?.metadata?.message || result?.response?.data?.metadata?.message,
        'error'
      );
    }
    setErpLoading(false);
  };
  const InitialErpCall = async () => {
    if (userDetails?.activeIntegration && userDetails?.activeIntegration !== '') {
      localStorage.setItem('erpType', userDetails?.activeIntegration);
      let params = {
        data: SyncArray
      };
      const result = await syncData(params);
      if (result?.status === 200) {
        const getUserresult = await getUserInfo(cognitoUsername);
        if (getUserresult?.status === 200) {
          dispatch(user_information(getUserresult?.data?.payload?.data));
        }
      } else if (result?.response?.status === 401) {
        localStorage.clear();
        navigate('/');
      }
    }
  };
  useEffect(() => {
    allQueuesData();
    InitialErpCall();
    LoadBatchDocs();
  }, []);
  const handleClear = () => {
    intervalCall();
    setRecordExist(false);
    setFilteredData([]);
    setSearchProducts('');
  };
  const handleClearAttachmentSearch = () => {
    setIsAttachmentSearched(false);
    setUploadAttachment(true);
    setSearchAttachments('');
    setSearchedResult([]);
    localStorage?.setItem('instrumentId', '');
  };
  const handleSearchAttachment = async (id) => {
    setIsLoading(true);
    const result = await searchAttachmentById(id || searchAttachments);
    setSearchedResult([]);
    if (result?.status === 200) {
      setUploadAttachment(false);
      if (!isEmpty(result?.data?.payload?.data)) {
        setSearchedResult(result?.data?.payload?.data);
        setInstumentId(result?.data?.payload?.data?.attachment?.referenceNo);
        localStorage?.setItem(
          'instrumentId',
          id || result?.data?.payload?.data?.attachment?.referenceNo
        );
      } else {
        setSearchedResult([]);
        setIsAttachmentSearched(true);
      }
    } else {
      showToast(
        result?.data?.metadata?.message || result?.response?.data?.metadata?.message,
        'error'
      );
    }
    setIsLoading(false);
  };
  const handleClearErpSearch = () => {
    setIsChequeSearched(false);
    setSearchedCheques([]);
    setChequeNum('');
    localStorage?.setItem('chequeNo', '');
  };
  const handleSearchFromErp = async (key) => {
    localStorage.setItem('erpType', erpType);
    setIsChequeLoading(true);
    const result = await searchbyChequeNumber(key || chequeNum);
    setSearchedCheques([]);
    if (result?.status === 200) {
      if (!isEmpty(result?.data?.payload?.data)) {
        setSearchedCheques(result?.data?.payload?.data);
        localStorage?.setItem('chequeNo', key || chequeNum);
      } else {
        setSearchedCheques([]);
        setIsChequeSearched(true);
      }
    } else {
      showToast(
        result?.data?.metadata?.message || result?.response?.data?.metadata?.message,
        'error'
      );
    }
    setIsChequeLoading(false);
  };
  const batchDocColumns = [
    {
      field: 'date',
      filterable: false,
      headerName: (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <CallReceivedOutlined
            sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
          />
          {RECIEVED_AT}
        </div>
      ),
      flex: 1,
      minWidth: 200,
      sortable: false
    },
    {
      field: 'file',
      headerName: FILE_NAME,
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <DescriptionOutlined
            sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
          />
          {FILE_NAME}
        </div>
      ),
      flex: 1,
      minWidth: 240,
      sortable: false
    },
    {
      field: 'noOfInvoices',
      filterable: false,
      headerName: (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <InsertDriveFileOutlined
            sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
          />
          {NO_OF_INVOICES}
        </div>
      ),
      flex: 1,
      minWidth: 240,
      sortable: false
    },

    {
      field: 'action',
      filterable: false,
      sortable: false,
      headerName: (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <HighlightAltOutlined
            sx={{
              color: Colors.TOURQUISE,
              fontSize: '17px',
              marginRight: '4px'
            }}
          />
          {ACTION}
        </div>
      ),
      flex: 1,
      minWidth: 100,
      sortable: false,
      disablecolumnmenu: false,
      renderCell: (params) => (
        <MuiModal
          text="View"
          data={params?.row}
          show="batchDocs"
          color={Colors.TOURQUISE}
          btnHeight="2rem"
          width="5rem"
          LargeScreenWidth="61vw"
          XLScreenWidth="61vw"
          getbatches={getbatches}
        />
      )
    }
  ];
  return (
    <ThemeProvider theme={theme}>
      <Grid container sx={{ height: '100vh', backgroundColor: 'primary.light' }}>
        <Layout
          activeTab={parentActiveTab}
          setActiveTab={setActiveTab}
          currentTabs={parentTabs}
          filteredData={filteredData}
          recordExist={recordExist}
          handleClear={handleClear}
          path="owner"
          handleClearAttachmentSearch={handleClearAttachmentSearch}
          isAttachmentSearched={isAttachmentSearched}
          handleClearErpSearch={handleClearErpSearch}
        />
        <Grid container sx={{ paddingX: { lg: '3rem' } }}>
          <Grid
            container
            sx={{
              height: '81vh'
            }}>
            <HeaderSection
              currentTabs={
                parentActiveTab === 'Documents'
                  ? childTabs
                  : parentActiveTab === 'Integrations'
                  ? integrationTabs
                  : parentActiveTab === 'Dashboard'
                  ? dashboardTabs
                  : featuresTabs
              }
              setActiveTab={
                parentActiveTab === 'Documents'
                  ? setChildActiveTab
                  : parentActiveTab === 'Integrations'
                  ? setIntegrationChildTab
                  : parentActiveTab === 'Dashboard'
                  ? setDashboardChildTabs
                  : setFeaturesChildTab
              }
              activeTab={
                parentActiveTab === 'Documents'
                  ? childActiveTab
                  : parentActiveTab === 'Integrations'
                  ? integrationChildTab
                  : parentActiveTab === 'Dashboard'
                  ? dashboardChildTabs
                  : featuresChildTab
              }
              setLoading={setLoading}
              loading={loading}
              checkedIds={checkedIds}
              setCheckedIds={setCheckedIds}
              filteredData={filteredData}
              featuresChildTab={featuresChildTab}
              setFilteredData={setFilteredData}
              recordExist={recordExist}
              setRecordExist={setRecordExist}
              intervalCall={intervalCall}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              tab={
                parentActiveTab === 'Documents'
                  ? 'documents'
                  : parentActiveTab === 'Integrations'
                  ? 'integration'
                  : parentActiveTab === 'Dashboard'
                  ? 'Dashboard'
                  : parentActiveTab === 'Attachments'
                  ? 'attachments'
                  : 'features'
              }
              integrationChildTab={integrationChildTab}
              handleSyncErp={handleSyncErp}
              erpLoading={erpLoading}
              currentUser="Customer"
              handleCreateCSV={handleCreateCSV}
              createCsvLoading={createCsvLoading}
              searchProducts={searchProducts}
              setSearchProducts={setSearchProducts}
              dataAccordingTabs={dataAccordingTabs}
              searchAttachments={searchAttachments}
              setSearchAttachments={setSearchAttachments}
              isAttachmentSearched={isAttachmentSearched}
              handleClearAttachmentSearch={handleClearAttachmentSearch}
              searchedResult={searchedResult}
              uploadAttachment={uploadAttachment}
              setUploadAttachment={setUploadAttachment}
              handleSearchAttachment={handleSearchAttachment}
              setChequeNum={setChequeNum}
              handleSearchFromErp={handleSearchFromErp}
              chequeNum={chequeNum}
              isChequeSearched={isChequeSearched}
              searchedCheques={searchedCheques}
              setSearchedCheques={setSearchedCheques}
              handleClearErpSearch={handleClearErpSearch}
              setSyncElement={setSyncElement}
              syncElement={syncElement}
              selectedRowsHasPO={selectedRowsHasPO}
              documentCount={documentCount}
            />
            {parentActiveTab === 'Documents' ? (
              <>
                {loading || batchDocsLoading ? (
                  <Grid
                    container
                    sx={{
                      height: '75vh',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <CircularProgress size={150} color="primary" />
                  </Grid>
                ) : childActiveTab !== 'Email' ? (
                  <DataTable
                    resdata={pendingQueues}
                    setCheckedIds={setCheckedIds}
                    checkedIds={checkedIds}
                    filterData={filteredData}
                    toShow="ApiSearch"
                    recordExist={recordExist}
                    currentUser="Customer"
                    isIntegrated={activeIntegration}
                    childActiveTab={childActiveTab}
                    setDataAccordingTabs={setDataAccordingTabs}
                    setSelectedRowsHasPO={setSelectedRowsHasPO}
                  />
                ) : (
                  <AdminDataTable
                    apiData={batchDocumentList}
                    rows={batchDocs}
                    setRows={setBatchDocs}
                    columns={batchDocColumns}
                    overlay={true}
                  />
                )}
              </>
            ) : parentActiveTab === 'Integrations' ? (
              <>
                {integrationChildTab === 'My Integration' ? (
                  <MyIntegerations />
                ) : integrationChildTab === 'Search Integration' ? (
                  <ErpDocuments
                    isLoading={isChequeLoading}
                    handleSearchFromErp={handleSearchFromErp}
                    setChequeNum={setChequeNum}
                    isChequeSearched={isChequeSearched}
                    searchedResult={searchedCheques}
                  />
                ) : (
                  <ManualSync />
                )}
              </>
            ) : parentActiveTab === 'Dashboard' ? (
              <Dashboard documentCount={documentCount} setDocumentCount={setDocumentCount} />
            ) : parentActiveTab === 'Attachments' && !uploadAttachment ? (
              <Attachments
                searchedResult={searchedResult}
                isAttachmentSearched={isAttachmentSearched}
                isLoading={isLoading}
                handleSearchAttachment={handleSearchAttachment}
                setSearchAttachments={setSearchAttachments}
                instrumentId={instrumentId}
                setInstumentId={setInstumentId}
              />
            ) : parentActiveTab === 'Attachments' && uploadAttachment ? (
              <UploadAttachments />
            ) : parentActiveTab === 'Policy' ? (
              <Policy />
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default HomePage;
