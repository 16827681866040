import * as React from 'react';

import { Box, Button, IconButton, Modal, Typography, Tooltip } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Close, RemoveRedEye } from '@mui/icons-material';

import CreateBill from './createBill';
import DocumentList from './documentList';
import { Colors } from '../config/default';
import AddUser from './addUser';
import ManualApprovalSelections from './manualApprovalSelections';
import AdminManualUsers from './adminManualUsers';
import DuplicateDoc from './duplicateDoc';
import RegisterVendor from './registerVendor';
import EditVendor from './editVendor';
import Approvers from './approvers';
import { HtmlTooltip } from '../services/common';
import AttachDeliveryTicket from './attachDeliveryTicket';
import Queue from './queue';
import SupportingDocument from './supportingDocument';
import BactchDocuments from './bactchDocuments';

export default function MuiModal({
  text,
  btnHeight,
  show,
  data,
  username,
  icon,
  getUsers,
  mails,
  setMails,
  apiData,
  marginBottom,
  variant,
  submitData,
  color,
  width,
  disable,
  getManualUser,
  id,
  opened,
  setOpened,
  documentName,
  getAllVendors,
  mr,
  nextApprover,
  previousApprover,
  setImages,
  setRefId,
  vendor,
  po,
  invoiceNumber,
  date,
  LargeScreenWidth,
  smallScreenWidth,
  mediumScreenWidth,
  XLScreenWidth,
  images,
  annotationType,
  deliveryTicketUpload,
  poMatchPermission,
  lineItemsData,
  lineItem,
  setIndex,
  getbatches
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    if (show === 'manualUsers') {
      setMails([]);
    }
    if (show === 'duplicateDocument') {
      setOpened(false);
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: Colors.Canvas_BG,
    borderRadius: '20px',
    p: 3,
    zIndex: '99'
  };

  return (
    <div>
      {show === 'manualUsers' ? (
        <KeyboardArrowDownIcon onClick={handleOpen} sx={{ marginTop: '.2rem', fontSize: '22px' }} />
      ) : show === 'duplicateDocument' ? (
        ''
      ) : show === 'batchDocs' ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
          onClick={handleOpen}>
          <RemoveRedEye sx={{ fontSize: '20px', marginRight: '.5rem', color: Colors.TOURQUISE }} />{' '}
          View
        </div>
      ) : show === 'deliveryTicket' || show === 'supportingDoc' ? (
        <HtmlTooltip
          title={
            <Typography color="inherit" sx={{ fontSize: '1em' }}>
              {text}
            </Typography>
          }>
          <IconButton
            variant="outlined"
            onClick={handleOpen}
            sx={{
              mr: '.5rem',
              border: '.5px solid #2E97A7'
            }}>
            {icon}
          </IconButton>
        </HtmlTooltip>
      ) : (
        <Button
          onClick={handleOpen}
          variant={variant ? variant : 'outlined'}
          disabled={disable}
          sx={{
            textTransform: 'unset !important',
            borderRadius: '10px',
            height: btnHeight,
            color: Colors.WHITE,
            width: width ? width : '',
            marginBottom: marginBottom ? marginBottom : '',
            borderColor: color ? color : Colors.DEEPBLUE,
            '&:hover': {
              borderColor: color ? color : Colors.DEEPBLUE,
              backgroundColor: 'transparent'
            },
            marginRight: mr ? mr : ''
          }}>
          {icon ? icon : ''}
          {text}
        </Button>
      )}
      <Modal
        open={show === 'duplicateDocument' ? opened : open}
        onClose={show === 'deliveryTicket' ? null : handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            ...style,
            width: {
              xs: smallScreenWidth || '90vw',
              md: mediumScreenWidth || '90vw',
              lg: LargeScreenWidth || '30vw',
              xl: XLScreenWidth || '30vw'
            }
          }}>
          <Close
            sx={{
              color: Colors.TEXTGREY,
              top: show === 'deliveryTicket' ? '10px' : '20px',
              right: show === 'deliveryTicket' ? '10px' : '20px',
              cursor: 'pointer',
              position: 'absolute'
            }}
            onClick={handleClose}
          />
          {show === 'vendorsDocuments' ? (
            <DocumentList documents={data} />
          ) : show === 'Add_Users' || show === 'Register_Users' ? (
            <AddUser label="Email" getUsers={getUsers} setOpen={setOpen} show={show} data={data} />
          ) : show === 'manualUsers' ? (
            <ManualApprovalSelections
              apiData={apiData}
              mails={mails}
              setMails={setMails}
              submitData={submitData}
            />
          ) : show === 'manual users' ? (
            <AdminManualUsers
              username={username}
              data={data}
              setOpen={setOpen}
              getManualUser={getManualUser}
            />
          ) : show === 'duplicateDocument' ? (
            <DuplicateDoc
              handleClose={handleClose}
              setOpened={setOpened}
              id={id}
              documentName={documentName}
            />
          ) : show === 'register vendor' ? (
            <RegisterVendor setOpen={setOpen} getAllVendors={getAllVendors} />
          ) : show === 'editVendor' ? (
            <EditVendor setOpen={setOpen} data={data} getAllVendors={getAllVendors} />
          ) : show === 'createBillVendor' ? (
            <CreateBill show="api" setOpen={setOpen} userEmail={data} role="Vendor" />
          ) : show === 'approvers' ? (
            <Approvers previousApprover={previousApprover} nextApprover={nextApprover} />
          ) : show === 'deliveryTicket' ? (
            <AttachDeliveryTicket
              setImages={setImages}
              setOpen={setOpen}
              setRefId={setRefId}
              refId={id}
              vendor={vendor}
              po={po}
              invoiceNumber={invoiceNumber}
              date={date}
              setIndex={setIndex}
            />
          ) : show === 'QBD Queue' ? (
            <Queue setModelOpen={setOpen} />
          ) : show === 'supportingDoc' ? (
            <SupportingDocument
              refId={id}
              po={po}
              images={images}
              annotationType={annotationType}
              deliveryTicketUpload={deliveryTicketUpload}
              poMatchPermission={poMatchPermission}
              lineItem={lineItem}
              lineItemsData={lineItemsData}
            />
          ) : show === 'batchDocs' ? (
            <BactchDocuments setOpen={setOpen} data={data} getbatches={getbatches} />
          ) : (
            <CreateBill show="app" setOpen={setOpen} userEmail={data} role="User" />
          )}
        </Box>
      </Modal>
    </div>
  );
}
