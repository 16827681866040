import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { CircularProgress, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import TimelineActivity from './timelineActivity';
import { Colors } from '../config/default';
import { getActivity } from '../services/services';
import { accountDetailsComponent } from '../constants/appConstants';

export default function ActivityCard({ id, documentType }) {
  const { ACTIVITY } = accountDetailsComponent;
  const [actData, setActData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const selectedData = async () => {
    setLoading(true);
    const result = await getActivity(id, documentType);
    if (result?.status === 200) {
      setLoading(false);
      setActData(result?.data?.payload?.data?.activity);
    } else if (result?.response?.status === 401) {
      localStorage.clear();
      navigate('/');
    }
  };
  useEffect(() => {
    selectedData();
  }, []);
  return (
    <>
      <Grid>
        <Grid
          container
          sx={{ padding: '0em 2em', mt: '1.5em', mb: '1em', justifyContent: 'flex-end' }}>
          <CloseIcon sx={{ cursor: 'pointer', fontSize: '1.5em', color: Colors.LIGHTGREY }} />
        </Grid>
        <Grid
          container
          sx={{
            height: { xs: '75vh', xl: '47vh' },
            width: { xs: '20em', sm: '28em', xl: '42em' },
            padding: '1em 0.8em',
            borderRadius: '1em',
            backgroundColor: Colors.BG_BLACK,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            m: '0em 2em'
          }}>
          <Grid item>
            <Typography sx={{ color: Colors.TOURQUISE, fontSize: '1em', ml: '1.1em' }}>
              {ACTIVITY}
            </Typography>
          </Grid>
          {loading ? (
            <Grid container sx={{ alignItems: 'center', justifyContent: 'center', height: '60vh' }}>
              <CircularProgress size={22} sx={{ color: Colors.DARKBLUE }} />
            </Grid>
          ) : (
            <Grid
              item
              sx={{
                height: { xs: '65vh', xl: '35vh' },
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '0em',
                  height: '0em',
                  borderRadius: '20px'
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
                  outline: '1px solid slategrey'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#fff',
                  outline: '1px solid slategrey'
                }
              }}>
              {actData?.map(
                (item, index) =>
                  (index !== 1 || documentType === 'Po') && (
                    <TimelineActivity
                      key={index}
                      timelineLeftContent={item}
                      timelineRightContent={item?.updatedObj}
                      documentType={documentType}
                      activityIndex={index}
                    />
                  )
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
